import React from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";

const FlowRouterRedirect = ({ from, to, id, loginPath }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  if (!id && pathname !== loginPath) {
    history.push(loginPath);
    return null;
  }

  return (
    <Route path={from}>
      <Redirect exact strict from={from} to={`${to}/${id}`} />
    </Route>
  );
};
export default FlowRouterRedirect;
