import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconCarFilled, IconVanFilled } from "wg-fe-ui";

const VehicleFormCategorySelector = ({ onChange, value }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Label>{t("Car type")}</Label>
      <RadioBtnContainer>
        <StyledInput
          onChange={onChange}
          type="radio"
          checked={value === "PASSENGER_CAR"}
          name="PASSENGER_CAR"
          id="PASSENGER_CAR"
          value="PASSENGER_CAR"
        />
        <StyledInputLabel htmlFor="PASSENGER_CAR">
          <IconCarFilled />
          <p>{t("PASSENGER_CAR")}</p>
        </StyledInputLabel>
        <StyledInput
          onChange={onChange}
          type="radio"
          name="LIGHT_COMMERCIAL_VEHICLE"
          checked={value === "LIGHT_COMMERCIAL_VEHICLE"}
          id="LIGHT_COMMERCIAL_VEHICLE"
          value="LIGHT_COMMERCIAL_VEHICLE"
        />
        <StyledInputLabel htmlFor="LIGHT_COMMERCIAL_VEHICLE">
          <IconVanFilled />
          <p>{t("LIGHT_COMMERCIAL_VEHICLE")}</p>
        </StyledInputLabel>
      </RadioBtnContainer>
    </div>
  );
};

const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.labels.guaranteeText};
`;

const StyledInput = styled.input`
  display: none;

  &:checked + label {
    justify-content: center;
    background-color: ${({ theme }) => theme.brand.lightest};
    border-color: ${({ theme }) => theme.brand.primary};
    @media (max-width: 1350px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

const RadioBtnContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-top: 0.8rem;
  @media (max-width: 520px) {
    grid-template-columns: auto;
  }
`;

const StyledInputLabel = styled.label`
  border: 1px solid rgb(204, 204, 204);
  color: rgb(80, 80, 80);
  border-radius: 6px;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  display: grid;
  grid-template-columns: 20% 80%;

  svg {
    stroke: ${({ theme }) => theme.brand.primary};
    path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
`;

export default VehicleFormCategorySelector;
