export const driverLicenseTypeOptions = [
  { value: "definitive", label: "definitive" },
  { value: "provisional", label: "provisional" },
];

export const numberOfAccidentsLastFiveYearsOptions = [
  { value: "0", label: "none" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "more_than_5", label: "more than 5" },
];

export const primaryOptions = [
  { value: "yes", label: "yes" },
  { value: "no", label: "no" },
];

export const specialEmployment = [
  { value: "yes", label: "yes" },
  { value: "no", label: "no" },
];

export const usedForOptions = [
  { value: "private", label: "private" },
  { value: "mixed", label: "private_professional" },
  { value: "professional", label: "professional" },
];

export const fuelType = [{ value: "ELE", label: "Electricity" }];

export const accaptances = [
  {
    key: "generalAcceptanceCloseOnline",
    title: "Online communication",
    short: "I agree to receive my documents by e-mail",
    long:
      "By purchasing this insurance online, I agree to receive my contract (general and special conditions) and all other documents relating to it by e-mail",
  },
  {
    key: "generalAcceptanceNotTerminated",
    title: "No prior notice of termination due to damage",
    short:
      "I confirm that my previous insurances were not cancelled due to a claim",
    long:
      "I confirm that one or more of my previous insurances, if any, have not been cancelled by the respective insurance companies for a claim",
  },
  {
    key: "generalAcceptanceNotRefused",
    title: "No previous refusal",
    short:
      "I confirm that I have no previous refused applications for the selected coverages",
    long:
      "I confirm that I do not have any previous applications refused by an insurance company for any of the selected coverages",
  },
  {
    key: "generalAcceptanceTerms",
    title: "General terms and conditions",
    short: "I agree with the terms and conditions",
    long:
      "I agree to the terms and conditions including my 14 calendar day right of withdrawal and confirm that the information provided is truthful",
  },
  {
    key: "generalAcceptancePrivacy",
    title: "Privacy policy",
    short: "I agree with the privacy policy",
    long:
      "I agree with the privacy policy and confirm that the content of the chosen insurance contract meets my requirements and needs",
  },
];
