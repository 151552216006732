import React from "react";
import styled from "styled-components";

const DocumentIcon = () => {
  return (
    <DocumentIconContainer
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.28 16H30.28C30.2402 16.0014 30.2006 15.9946 30.1636 15.98C30.1265 15.9655 30.0929 15.9434 30.0648 15.9153C30.0366 15.8871 30.0146 15.8535 30 15.8165C29.9854 15.7794 29.9786 15.7398 29.98 15.7V8.70002C29.9808 8.6413 29.9989 8.58412 30.0319 8.53556C30.0649 8.487 30.1114 8.44919 30.1657 8.42683C30.22 8.40447 30.2797 8.39854 30.3374 8.40978C30.395 8.42101 30.4481 8.44891 30.49 8.49002L37.49 15.49C37.5311 15.532 37.559 15.585 37.5702 15.6427C37.5815 15.7003 37.5755 15.76 37.5532 15.8143C37.5308 15.8686 37.493 15.9151 37.4445 15.9481C37.3959 15.9812 37.3387 15.9992 37.28 16Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7 18H28.3C28.2602 18.0014 28.2206 17.9946 28.1836 17.98C28.1465 17.9654 28.1129 17.9434 28.0848 17.9152C28.0566 17.8871 28.0346 17.8535 28.02 17.8164C28.0054 17.7794 27.9986 17.7398 28 17.7V8.3C28.0014 8.26022 27.9946 8.22059 27.98 8.18356C27.9654 8.14653 27.9434 8.1129 27.9152 8.08476C27.8871 8.05662 27.8535 8.03457 27.8164 8.01999C27.7794 8.0054 27.7398 7.9986 27.7 8H12.3C12.2602 7.9986 12.2206 8.0054 12.1836 8.01999C12.1465 8.03457 12.1129 8.05662 12.0848 8.08476C12.0566 8.1129 12.0346 8.14653 12.02 8.18356C12.0054 8.22059 11.9986 8.26022 12 8.3V41.7C11.9986 41.7398 12.0054 41.7794 12.02 41.8164C12.0346 41.8535 12.0566 41.8871 12.0848 41.9152C12.1129 41.9434 12.1465 41.9654 12.1836 41.98C12.2206 41.9946 12.2602 42.0014 12.3 42H37.7C37.7398 42.0014 37.7794 41.9946 37.8164 41.98C37.8535 41.9654 37.8871 41.9434 37.9152 41.9152C37.9434 41.8871 37.9654 41.8535 37.98 41.8164C37.9946 41.7794 38.0014 41.7398 38 41.7V18.3C38.0014 18.2602 37.9946 18.2206 37.98 18.1836C37.9654 18.1465 37.9434 18.1129 37.9152 18.0848C37.8871 18.0566 37.8535 18.0346 37.8164 18.02C37.7794 18.0054 37.7398 17.9986 37.7 18ZM31.22 23.56L25.39 30.65C25.3079 30.7532 25.2049 30.8378 25.0876 30.8981C24.9704 30.9585 24.8417 30.9932 24.71 31H24.66C24.406 30.9964 24.1629 30.8963 23.98 30.72L20.28 27C20.1411 26.9272 20.0213 26.8227 19.9302 26.695C19.8392 26.5674 19.7794 26.4201 19.7558 26.265C19.7321 26.11 19.7453 25.9516 19.7942 25.8026C19.8431 25.6535 19.9263 25.5181 20.0372 25.4072C20.1481 25.2963 20.2835 25.2131 20.4326 25.1642C20.5816 25.1153 20.74 25.1021 20.895 25.1258C21.0501 25.1494 21.1974 25.2092 21.325 25.3002C21.4527 25.3913 21.5572 25.5111 21.63 25.65L24.63 28.65L29.79 22.37C29.8685 22.2721 29.9658 22.1909 30.0761 22.1312C30.1865 22.0715 30.3077 22.0345 30.4326 22.0224C30.5575 22.0103 30.6835 22.0233 30.8033 22.0607C30.9231 22.0981 31.0342 22.159 31.13 22.24C31.3032 22.4113 31.4079 22.6399 31.4245 22.883C31.4411 23.126 31.3684 23.3668 31.22 23.56Z"
        fill="white"
      />
    </DocumentIconContainer>
  );
};

const DocumentIconContainer = styled.svg`
  align-self: center;
  margin-left: 1rem;
  padding-right: 2rem;
`;

export default DocumentIcon;
