import { setLoggedIn } from "./authService";
import { request } from "./httpSessionService";
import i18n from "../i18n.js";

/* * * * * * * * * * * * * * *
 * AUTHENTICATION
 * * * * * * * * * * * * * * */
export async function authMe() {
  const path = "v1/api/aut/v1/api/auth/me";
  const [resp, status] = await request(path, "GET");
  if (status === 200) {
    setLoggedIn({
      email: resp.me.email,
      language: resp.me.language,
      role: resp.me.role
    });
  } else {
    console.error(resp, status);
    alert("Something went wrong @ authme");
  }
  return [resp, status];
}

export async function auth(email, password) {
  const path = "v1/api/aut/v1/api/auth";
  const [resp, status] = await request(path, "POST", {
    email: email,
    password: password
  });
  if (status === 200) {
    sessionStorage.setItem("access_token", resp?.access_token);
    sessionStorage.setItem("refresh_token", resp?.refresh_token);
  }
  return [resp, status];
}

export async function verifyInit() {
  /*
   * the verify method that is called on page load
   * it checks if you have cookies set.
   *
   * if there are expired cookies, renew them and go to dashboard
   * if no cookies found redirect to login page
   *
   */
  const path = "v1/api/aut/v1/api/auth/verify";
  const [resp, status] = await request(path, "GET");
  if (status == 401) {
    return [resp, status];
  } else {
    return await authMe();
  }
}

export async function captchaAuth({
  captcha,
  email,
  firstName,
  lastName,
  askBrokerId
}) {
  const path = "v1/api/aut/v1/api/auth";
  const payload = {
    ask_role: "social_login",
    captcha,
    captcha_user_information: {
      email: email,
      first_name: firstName,
      last_name: lastName
    },
    ask_broker_id: askBrokerId
  };
  const [resp, status] = await request(path, "POST", payload);
  if (status === 200) {
    sessionStorage.setItem("access_token", resp?.access_token);
    sessionStorage.setItem("refresh_token", resp?.refresh_token);
  }
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * BROKER DATA
 * * * * * * * * * * * * * * */

export async function retrieveBrokerById(id) {
  const path = `v1/api/broker/v1/api/broker/${id}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CAR DATA
 * * * * * * * * * * * * * * */
export async function retrieveCarBrands(query) {
  const q = query ? `?${query}` : "";
  const path = `v1/api/car/v1/api/brands${q}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarModels(query, brand) {
  const q = query ? `?${query}` : "";
  const path = `v1/api/car/v1/api/models${q}`;
  const payload = { brand };
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveCarVerions(query, brand, model) {
  const q = query ? `?${query}` : "";
  const path = `v1/api/car/v1/api/versions${q}`;
  const payload = { brand, model };
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveCarInfo(brand, model, version, modelYear, kw) {
  const path = `v1/api/car/v1/api/car`;
  const payload = { brand, model, version, model_year: modelYear, kw: kw };
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveCarPrice(payload, query) {
  const q = query ? `?${query}` : "";
  const path = `v1/api/quotes/v1/api/quote/car/allianz/elvas${q}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}


/* * * * * * * * * * * * * * *
 * VEHICLE DATA
 * * * * * * * * * * * * * * */

export async function retrieveCarBrandsV2(category, query) {
  let cat = category ? `category=${category}` : null;
  const params =
    "?" + (query ? (category ? query + "&" + cat : query) : cat) + "&pagelen=500";
  const path = `v1/api/vehicle/v1/api/automobile/brands${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarModelsV2(category, brandKey, query) {
  let cat = category ? `category=${category}` : null;
  const params =
    "?" + (query ? (category ? query + "&" + cat : query) : cat) + "&pagelen=500";
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brandKey.toLowerCase()}/models${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarVersionsV2(
  category,
  brandKey,
  modelKey,
  query
) {
  let cat = category ? `category=${category}` : null;
  const params =
    "?" +
    (query ? (category ? query + "&" + cat : query) : cat) +
    "&pagelen=500";
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brandKey.toLowerCase()}/models/${modelKey}/versions${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarModelByIdV2(brandKey, modelKey, versionKey) {
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brandKey.toLowerCase()}/models/${modelKey}/versions/${versionKey}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}


/* * * * * * * * * * * * * * *
 * ADDRESS
 * * * * * * * * * * * * * * */

export async function retrieveAddress(payload) {
  const path = `v1/api/address/v1/api/search?q=${payload}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveAddressStreets(payload) {
  let path;
  if (payload.searchQuery) {
    path = `v1/api/address/v1/api/streets?q=${payload.searchQuery}&q_city=${payload.city}`;
  } else if (payload.city) {
    path = `v1/api/address/v1/api/streets?q_city=${payload.city}`;
  }
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveAddressCities(payload) {
  let path;
  if (payload.zipcode && !payload.searchQuery) {
    path = `v1/api/address/v1/api/cities?zipcode=${payload.zipcode}`;
  } else if (payload.searchQuery && !payload.zipcode) {
    path = `v1/api/address/v1/api/cities?q=${payload.searchQuery}`;
  }
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * POLICY
 * * * * * * * * * * * * * * */

export async function retrieveSignature(payload) {
  // const path = `v1/api/policy/v1/api/purchase?lang=${i18n.language}`;
  const path = `v1/api/broker/v1/api/elvas-offers?lang=${i18n.language}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * INSURERS
 * * * * * * * * * * * * * * */

export async function retrieveInsurers() {
  const path = `v1/api/marketplace/v1/api/retrieve_insurance_companies/${i18n.language}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * VALIDATE
 * * * * * * * * * * * * * * */

export async function checkIfCBEIsValid(_cbe) {
  const path = `v1/api/broker/v1/api/validate/cbe`;
  const [resp, status] = await request(path, "POST", { cbe: _cbe });
  return [resp, status];
}
export async function isRegisterNrValid(nr) {
  const path = "v1/api/validate/national_identification_number";
  const [resp, status] = await request(
    path,
    "POST",
    { nr: parseInt(nr.replaceAll(/[.-]/gm, "")), country: "BE" },
    true
  );
  return [resp, status];
}
