import React, { useEffect, useState } from "react";
import { SearchSelectInput, TextBold, ActionButton } from "wg-fe-ui";
import styled from "styled-components";
import * as Yup from "yup";
import i18n from "../i18n";

import FlowOccupationInformationJobsList from "../components/FlowOccupationInformationJobsList.jsx";

import { specialEmployment } from "../constants/FlowSearchSelectData";
import useForm from "../hooks/useForm";
import { string } from "../constants/validationSchemas.js";

const FlowOccupationInformationQuestion = ({
  handleFormValues,
  defaultValues
}) => {
  const [specialEmploymentOptions, setSpecialEmploymentOptions] = useState([]);
  const SignupSchema = Yup.object().shape({
    specialEmployment: string.required
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {}
  });

  useEffect(() => {
    setDefaultValues();
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setSpecialEmploymentOptions(
      specialEmployment.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function setDefaultValues() {
    Object.keys(defaultValues).map(name => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleFormValues);
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  const SelectPlaceholder = i18n.t("Choose your option");

  return (
    <Form onSubmit={formSubmitHandler}>
      <div>
        <TextBold>
          {i18n.t("Are you practicing one of the following professions")}
        </TextBold>
        <SelectInput
          error={errors.specialEmployment}
          name="specialEmployment"
          onChange={val => {
            handleSelectChange(val, "specialEmployment");
          }}
          options={specialEmploymentOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={handleSelectValue(
            specialEmploymentOptions,
            "specialEmployment"
          )}
        />
      </div>
      <FlowOccupationInformationJobsList />
      <ButtonContainer>
        <ActionButton
          type="submit"
          value="Login"
          data-test-id="social_login_submit"
        >
          {i18n.t("Next")}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: grid;
  grid-template-rows: 18% 72% 10%;
  height: 100%;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default FlowOccupationInformationQuestion;
