import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";

import FlowDriversInformationForm from "../components/FlowDriversInformationForm.jsx";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const FlowDriversInformation = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, []);

  function handleSubmit(values) {
    patchStorage(values, id);
    renderNextRoute();
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `Hello I am Louise In order to calculate your personalised premium please answer the following questions on the right`
        )}
      />
      <RightSplit>
        <FlowDriversInformationForm
          handleFormValues={handleSubmit}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowDriversInformation;
