import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { LoadingSpinner } from "wg-fe-ui";
import styled from "styled-components";

import { retrieveSignature } from "../helpers/apiRouterService";
import { retrieveStorageById } from "../helpers/storeService.js";
import { getNestedObject } from "../helpers/objectService";

import i18n from "../i18n";
import { parseIncDateToObject } from "../helpers/dateService";

const FlowGeneratingDocuments = () => {
  const { id } = useParams();
  const { data } = retrieveStorageById(id) || {};
  const [error, setError] = useState(false);

  useEffect(() => {
    callSignature();
  }, []);

  function getNumbersFromString(str = "") {
    if (str.match(/\d/g)) {
      return str.match(/\d/g).join("");
    }
    return null;
  }

  function parseCBE(cbe) {
    return cbe ? cbe.replace(/\./g, "") : cbe;
  }

  async function callSignature() {
    let client = {
      billing_address: {
        street: getNestedObject(data, ["streetName"]),
        zipcode: getNestedObject(data, ["postalCode"]),
        housenr: getNestedObject(data, ["streetNumber"]),
        city: getNestedObject(data, ["municipalityName"]),
        boxnr: getNestedObject(data, ["boxNumber"]),
        country_code: "BE",
      },
      email: getNestedObject(data, ["email"]),
      telephonenr: getNestedObject(data, ["telephonenr"]),
    };

    // TODO @Aaron, remove this piece of code below and replace by retrieving the gloabl person_type variable from memory
    let person_type = "natural";
    if (getNestedObject(data, ["company_name"])) {
      person_type = "legal";
    }

    if (person_type == "natural") {
      client.first_name = getNestedObject(data, ["firstName"]);
      client.last_name = getNestedObject(data, ["lastName"]);
      client.birth = parseIncDateToObject(
        getNestedObject(data, ["naturalPersonBirth"])
      );
      client.national_register_nr = getNumbersFromString(
        getNestedObject(data, ["national_register_nr"])
      );
    }
    if (person_type == "legal") {
      client.name = getNestedObject(data, ["company_name"]);
      client.cbe = getNestedObject(data, ["cbe"]);
      if (client.cbe) {
        client.cbe = parseCBE(client.cbe);
      }
    }

    const arr = [];

    if (
      getNestedObject(data, ["attachments", "registrationForm"]) != undefined
    ) {
      arr.push(getNestedObject(data, ["attachments", "registrationForm"]));
    }
    if (getNestedObject(data, ["attachments", "receipt"]) != undefined) {
      arr.push(getNestedObject(data, ["attachments", "receipt"]));
    }

    const startDate = {
      day: getNestedObject(data, ["startDate"]).split("/")[0],
      month: getNestedObject(data, ["startDate"]).split("/")[1],
      year: getNestedObject(data, ["startDate"]).split("/")[2],
    };

    const dueDate = {
      day: 1,
      month: 1,
      year: parseInt(startDate.year) + 1,
    };

    const payload = {
      client: client,
      policy_contracts: [
        {
          start_date: startDate,
          due_date: dueDate,
          quote_id: getNestedObject(data, ["quote", "id"]),
          policy_documents: {
            terms: {
              type: "pdf",
              link: `http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_${i18n.language}.pdf`,
            },
            ipid: {
              type: "pdf",
              link: `http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_elvas_${i18n.language}.pdf`,
            },
          },
          insurance_name: "Elvas",
        },
      ],
      notices: generateNotice(),
      information_requirement_sheets: [
        {
          insurance: {
            name: "elvas",
            company: "Allianz N.V. & Euromex",
            type: "car",
            options: getSelectedOptions(),
          },
          specific_needs: generateSpecificNeeds(),
          motivation: i18n.t(`elvas_motivation`),
          reason_for_contact: i18n.t("elvas_reason_of_contact"),
        },
      ],
      attachments: arr,
      same_as_policy_holder: data.primary === "yes",
    };

    const [resp, status] = await retrieveSignature(payload);

    if (status === 201) {
      window.location.href = resp.url;
    } else {
      setError(true);
    }
  }

  function getSelectedOptions() {
    return data.quote.options.map(({ name }) => i18n.t(name));
  }

  function generateSpecificNeeds() {
    const specificNeedsArray = [];
    const selectedOptions = data.quote.options.map(({ name }) => name);
    const { type, value } = data.quote.franchise;

    specificNeedsArray.push(
      i18n.t("The customer opted for a payment of the premium", {
        interval: i18n.t("annual"),
      })
    );

    if (selectedOptions.includes("assistance_vehicle_person"))
      specificNeedsArray.push(
        i18n.t(
          "The customer chose to opt for a 'personal and vehicle assistance' coverage"
        )
      );

    if (selectedOptions.includes("driver"))
      specificNeedsArray.push(
        i18n.t("The customer chose to opt for a 'driver' coverage")
      );

    if (selectedOptions.includes("legal"))
      specificNeedsArray.push(
        i18n.t("The client chose to take 'legal' assistance")
      );

    if (selectedOptions.includes("omnium")) {
      specificNeedsArray.push(
        i18n.t("The customer opted for a franchise, amounting to €", {
          franchiseType: i18n.t(type),
          franchisePrice: value,
        })
      );

      specificNeedsArray.push(
        i18n.t("The customer chose to opt for an 'omnium' coverage")
      );
    }

    return specificNeedsArray.join("</br>");
  }

  function generateNotice() {
    if (
      getNestedObject(data, ["terminatePolicy"]) &&
      getNestedObject(data, ["terminationDate"])
    ) {
      const [day, month, year] = getNestedObject(data, [
        "terminationDate",
      ]).split("/");

      return [
        {
          insurance: {
            name: i18n.t(`Car insurance`),
            company_tag: getNestedObject(data, ["insuranceCompany"]),
          },
          policy_number: getNestedObject(data, ["policyNumber"]),
          due_date: { day, month, year },
          new_insurance_company_tag: "allianz",
        },
      ];
    } else {
      return [];
    }
  }

  return (
    <Container>
      <LoadingWrapper>
        {error ? (
          <P>
            {i18n.t(
              "Something went wrong with generating your documents please contact elvas"
            )}
          </P>
        ) : (
          <>
            <LoadingSpinner />
            <P>{i18n.t("Generating documents, please wait")}</P>
          </>
        )}
      </LoadingWrapper>
    </Container>
  );
};

const P = styled.p`
  margin-top: 2rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

export default FlowGeneratingDocuments;
