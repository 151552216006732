import React from "react";
import styled from "styled-components";
import i18n from "../i18n";

import { ActionButton, TextInput, PhoneInput } from "wg-fe-ui";
import FlowAddressContainer from "./FlowAddressContainer";
import InfoPopup from "./InfoPopup";

const FlowPolicyHolderInfoFormDefaultInputs = ({
  errors,
  values,
  handleChange,
}) => {
  const handlePhoneInput = (e) => {
    e.target.value = e.target.value.replace("+320", "+32");
    if (
      e.target.value.length > 4 &&
      e.target.value.substring(0, 4) === "0032"
    ) {
      e.target.value = "+32" + e.target.value.substring(4);
    }
    if (e.target.value.length > 0 && e.target.value[0] === "0") {
      e.target.value = "+32" + e.target.value.substring(1);
    }
    if (e.target.value.length < 3) {
      e.preventDefault();
      values.telephonenr = "+32";
    } else {
      handleChange({ name: e.target.name, value: e.target.value });
    }
  };

  return (
    <>
      <FlowAddressContainer
        errors={errors}
        values={values}
        handleChange={handleChange}
      />
      <FlexWrapper>
        <PhoneInput
          name="telephonenr"
          error={errors.telephonenr}
          onChange={handlePhoneInput}
          value={values.telephonenr}
          autoComplete="off"
        >
          <InputWrapper>{i18n.t("Phonenumber")} *</InputWrapper>
        </PhoneInput>
        <TextInput
          name="email"
          error={errors.email}
          onChange={(val) => handleChange(val)}
          value={values.email}
        >
          <InputWrapper>{i18n.t("Email")} *</InputWrapper>
        </TextInput>
      </FlexWrapper>
      <StyledHelperInput
        name="licensePlate"
        error={errors.licensePlate}
        onChange={(val) => handleChange(val)}
        value={values.licensePlate}
      >
        <div>
          {i18n.t("What is license plate of the car?")}
          <StyledInfoPopup
            title={i18n.t("What is license plate of the car?")}
            info={i18n.t(
              "If you don't have a license plate you can type for example not known"
            )}
          />
        </div>
      </StyledHelperInput>
      <StyledHelperInput
        name="vinNumber"
        error={errors.vinNumber}
        onChange={(val) => handleChange(val)}
        value={values.vinNumber}
      >
        <div>
          {i18n.t("What is the VIN number of the car?")} *
          <StyledInfoPopup
            title={i18n.t("What is the VIN number of the car?")}
            info={i18n.t(
              "If you don't have a chassis number yet you can type for example 000000000000000"
            )}
          />
        </div>
      </StyledHelperInput>
      <ButtonContainer>
        <Required>* {i18n.t("Required fields")}</Required>
        <ActionButton
          type="submit"
          value="Confirm"
          data-test-id="policy_holder_flow_submit"
        >
          {i18n.t("Confirm")}
        </ActionButton>
      </ButtonContainer>
    </>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
`;

const InputWrapper = styled.p`
  height: 1rem;
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
`;

const StyledHelperInput = styled(StyledTextInput)`
  input {
    margin-top: 0.8rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 10rem;

  & > div + div {
    /* width: 48%; */
    margin-left: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

export default FlowPolicyHolderInfoFormDefaultInputs;
