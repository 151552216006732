import React, { useState, useEffect } from "react";
import styled from "styled-components";

const TurningCheckboxArrow = ({ onChange, className }) => {
  const [isChecked, setIsChecked] = useState(false);

  function handleClick() {
    setIsChecked(!isChecked);
  }

  useEffect(() => {
    onChange(isChecked ? "checked" : "notChecked");
  }, [isChecked]);

  return (
    <Container className={className} onClick={handleClick}>
      <Svg
        data-test-id="underwriting_discount_arrow_1"
        viewBox="0 0 33 16"
        fill="none"
        width="100%"
        height="100%"
        isChecked={isChecked}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 14L16.5 2L31 14"
          stroke="#707070"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </Svg>
    </Container>
  );
};

const Container = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  cursor: pointer;
`;

const Svg = styled.svg`
  transition: 0.2s ease-out;
  transform: rotate(${({ isChecked }) => (isChecked ? "0deg" : "180deg")});
`;

export default TurningCheckboxArrow;
