import React, { useEffect, useState } from "react";
import { LoadingSpinner, ActionButton } from "wg-fe-ui";
import styled from "styled-components";
import { differenceInYears } from "date-fns";
import { useParams } from "react-router";
import qs from "query-string";

import FlowPriceCalculatorOptions from "../components/FlowPriceCalculatorOptions";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar";
import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";
import i18n from "../i18n";
import LouiseTextBubble from "../components/LouiseTextBubble";
import { getCarPrice, patchquoteOptionsAndPricing } from "../helpers/smtHelper";

const FlowPriceCalculator = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [premium, setPremium] = useState(0);
  const [hasFranchise, setHasFranchise] = useState(false);
  const [franchise, setFranchise] = useState({
    type: "",
    value: 0,
  });
  const [subPrices, setSubPrices] = useState({});
  const { renderNextRoute } = useRouting();

  useEffect(() => {
    setOptionsPrices();
  }, []);

  async function retrievePrice(queryString) {
    setHasFranchise(queryString.includes("omnium"));
    const resp = await callCarPrice(queryString);
    setResponseData(resp);
  }

  async function setOptionsPrices() {
    const options = ["driver", "assistance_vehicle_person", "legal"];
    // if the car is older than 5 years don't allow omnium
    if (!isCarOlderThan5Years()) {
      options.push("omnium");
    }
    let response = await callCarPrice(qs.stringify({ option: options }));

    if (isCarOlderThan5Years() && response) {
      response.quote.options.push({
        name: "omnium",
        total_premium: null,
        disabled: true,
      });
    }

    setSubPrices(response);
  }

  const isCarOlderThan5Years = () => {
    const { data } = retrieveStorageById(id) || {};
    const { registration_first } = data || {};
    return differenceInYears(new Date(), new Date(registration_first)) >= 5;
  };

  function setResponseData(response) {
    const { premium } = response || {};

    const { total_premium } = premium || {};
    setPremium(total_premium);

    const { franchise } = premium || {};
    setFranchise(franchise);

    patchquoteOptionsAndPricing(id, response);
  }

  async function callCarPrice(query = "") {
    patchStorage({ pricingQuery: query }, id);
    const [resp, status] = await getCarPrice(id);

    if ((await status) === 200) setLoading(false);
    else return {};

    return resp;
  }

  function handleSubmit() {
    renderNextRoute();
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>{i18n.t("Please wait")}</LoadingText>
      </LoadingContainer>
    );
  }

  var myObj = {
    style: "currency",
    currency: "EUR",
  };

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={
          hasFranchise
            ? i18n.t("Your yearly premium is with an english franchise of", {
                premium: parseFloat(premium).toLocaleString("nl-BE", myObj),
                franchiseValue: parseFloat(franchise.value).toLocaleString(
                  "nl-BE",
                  myObj
                ),
              })
            : i18n.t("Your yearly premium is", {
                premium: parseFloat(premium).toLocaleString("nl-BE", myObj),
              })
        }
        infoHelper={
          hasFranchise
            ? {
                title: i18n.t("English franchise"),
                info: i18n.t("english_franchise_description"),
              }
            : null
        }
      />
      <RightSplit>
        <StyledDiv>
          <FlowPriceCalculatorOptions
            subPrices={subPrices}
            onChange={(queryString) => retrievePrice(queryString)}
          />
          <LouiseTextBubble
            id="louiseBubble"
            chatText={
              hasFranchise
                ? i18n.t(
                    "Your yearly premium is with an english franchise of",
                    {
                      premium: parseFloat(premium).toLocaleString(
                        "nl-BE",
                        myObj
                      ),
                      franchiseValue: parseFloat(
                        franchise.value
                      ).toLocaleString("nl-BE", myObj),
                    }
                  )
                : i18n.t("Your yearly premium is", {
                    premium: parseFloat(premium).toLocaleString("nl-BE", myObj),
                  })
            }
            infoHelper={
              hasFranchise
                ? {
                    title: i18n.t("English franchise"),
                    info: i18n.t("english_franchise_description"),
                  }
                : null
            }
          />
          <Button
            onClick={() => {
              handleSubmit();
            }}
          >
            {i18n.t(`Confirm`)}
          </Button>
        </StyledDiv>
      </RightSplit>
    </SplitContainer>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingText = styled.p`
  margin-top: 2rem;
  font-size: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(ActionButton)`
  width: 20rem;
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  & .louiseBubble {
    display: none;
  }

  /* 768px */
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem 0rem;
  }

  @media (max-width: 570px) {
    /* padding: 4rem 2.5rem; */
    width: 95%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 1140px) {
    justify-content: center;
  }
`;

export default FlowPriceCalculator;
