import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import { retrieveStorageById, patchStorage } from "../helpers/storeService.js";
import FlowManualVehicleForm from "../components/FlowManualVehicleForm.jsx";
// import { LoadingSpinner } from "wg-fe-ui/dist";
import i18n from "../i18n";
import InfoPopup from "../components/InfoPopup";
import elvasLogo from "../assets/images/elvas_logo.svg";

const FlowCarInformation = () => {
  const defaultImg = "https://files.wegroup.be/avatars/avatar-louise.svg";
  const [carBrandImage, setCarBrandImage] = useState(defaultImg);
  const { renderNextRoute } = useRouting();
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
    if (data.carImg) {
      setCarBrandImage(data.carImg);
    }
  }, []);

  function handleSubmit(carInfo) {
    patchStorage(carInfo, id);
    renderNextRoute();
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <ElvasLogo src={elvasLogo} alt="" />
        <RoundImageContainer>
          <RoundImage img={carBrandImage} alt="car brand logo" />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                "Please enter your vehicle information on the right"
              )
            }}
            style={{ width: "90%" }}
          ></p>

          <StyledInfoPopup
            title={i18n.t(
              "Why are only electric vehicles (EVs) eligible and the plug in vehicles? (PHEV) not?"
            )}
            info={i18n.t(
              "Elvas starts from the principle that an EV is built differently from an ordinary car The car components are assembled differently, for example, there is proven to be less wear and tear on the car engine, brakes, etc A plug in (PHEV) still has an internal combustion engine, so this argument doesn't hold There is also the value of the battery and the underlying technology This makes the car a lot more complex, which creates the need for tailor-made insurance"
            )}
          />
        </TextBalloon>
      </LeftSplit>
      <RightSplit>
        <FlowManualVehicleForm
          resetImage={() => setCarBrandImage(defaultImg)}
          handleFormValues={handleSubmit}
          renderNextRoute={renderNextRoute}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const ElvasLogo = styled.img`
  width: 16rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  background-color: white;
  color: #188bee;
  float: right;
`;

const TextBalloon = styled.div`
  display: flex;
  font-size: 2rem;
  line-height: 1.25;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 4rem;

  :after {
    content: "";
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
  border-radius: 50%;
`;

const RoundImageContainer = styled.div`
  max-width: 33rem;
  width: 100%;
`;

// const LoadingContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export default FlowCarInformation;
