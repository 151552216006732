import React, { useEffect } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import i18n from "../i18n";
import cogoToast from "cogo-toast";
import LogRocket from "logrocket";
import "../assets/css/cogoToast.css";

import { useHistory, useLocation } from "react-router";
import { TextInput, ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import {
  firstName,
  lastName,
  email,
  captcha
} from "../constants/validationSchemas";

import elvasLogo from "../assets/images/elvas_logo.svg";

import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import useForm from "../hooks/useForm";
import { captchaAuth } from "../helpers/apiRouterService";
import { generateUUID } from "../helpers/uuidService";
import { initializeStorage, patchStorage } from "../helpers/storeService";

const Login = () => {
  const urlParams = new URLSearchParams(useLocation().search);
  const useLocationConst = useLocation().search === "?logoutStatus=expired";

  useEffect(() => {
    if (useLocationConst) {
      cogoToast.error(i18n.t("Your session has expired  Please log in again"), {
        position: "top-right"
      });
    }
  }, []);

  if (urlParams.has("lang")) {
    const toSetLanguage = urlParams.get("lang");
    const currentLanguage = i18n.language;
    if (toSetLanguage !== currentLanguage) i18n.changeLanguage(toSetLanguage);
  }

  const history = useHistory();
  const SignupSchema = Yup.object().shape({
    firstName,
    lastName,
    email,
    captcha
  });

  const { handleChange, values, handleSubmit, errors } = useForm({
    validationSchema: SignupSchema,
    change: () => {}
  });

  const onSubmit = ({ firstName, lastName, email, captcha }) => {
    authenticate(firstName, lastName, email, captcha);
    LogRocket.identify("Email Login", {
      email,
      name: `${firstName} ${lastName}`
    });
  };

  const authenticate = async (firstName, lastName, email, captcha) => {
    const [, status] = await captchaAuth({
      captcha,
      email,
      firstName,
      lastName,
      askBrokerId: "dc8bb689-1ac9-4e8b-8b42-5a4bdc2ce97d"
    });

    if (status !== 200) return;
    const id = generateUUID();
    initializeStorage(id);
    patchStorage({ firstName, lastName, email }, id);
    await history.push("/session");
  };

  const formSubmitHandler = e => {
    e.preventDefault();
    handleSubmit(onSubmit);
  };

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `Hello I am Louise In order to calculate your personalised premium please answer the following questions on the right`
        )}
      />
      <RightSplit>
        <Form onSubmit={formSubmitHandler} data-test-id="social_login_form">
          <ElvasLogo src={elvasLogo} alt="" />
          <FixedHeightInput
            data-test-id="social_login_first_name"
            name="firstName"
            type="text"
            error={errors.firstName}
            value={values.firstName}
            onChange={handleChange}
          >
            {i18n.t("First name")}
          </FixedHeightInput>
          <FixedHeightInput
            data-test-id="social_login_last_name"
            name="lastName"
            type="text"
            error={errors.lastName}
            value={values.lastName}
            onChange={handleChange}
          >
            {i18n.t("Last name")}
          </FixedHeightInput>
          <FixedHeightInput
            data-test-id="social_login_email"
            name="email"
            type="email"
            error={errors.email}
            value={values.email}
            onChange={handleChange}
          >
            {i18n.t("Email")}
          </FixedHeightInput>
          <Captcha
            sitekey="6LfXtssUAAAAAHPd30jLlGBYw2BjuyOu_QAqcRDq"
            onChange={e => handleChange({ name: "captcha", value: e })}
            hl={i18n.language}
          />
          <CaptchaError>
            <p>{errors.captcha}</p>
          </CaptchaError>
          <ButtonContainer>
            <ActionButton
              type="submit"
              value="Login"
              data-test-id="social_login_submit"
            >
              {i18n.t("Next")}
            </ActionButton>
          </ButtonContainer>
        </Form>
      </RightSplit>
    </SplitContainer>
  );
};

const CaptchaError = styled.div`
  color: #f74040;
  display: flex;
  font-size: 1.2rem;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  -webkit-box-pack: end;
 > p{
  text-align: right;
    width: 304px;
    margin-right: auto;
}
 }
`;

const ElvasLogo = styled.img`
  width: 16rem;
  margin: 0 auto 3rem auto;

  @media screen and (min-width: 426px) {
    display: none;
  }
`;

const Captcha = styled(ReCAPTCHA)`
  align-self: flex-end;
  margin-top: 1rem;
  width: 100%;
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FixedHeightInput = styled(TextInput)`
  height: 9rem;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default Login;
