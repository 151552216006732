import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import {
  IconCarFilled,
  IconLookingForCarFilled,
  IconSecondHandCarFilled,
  IconIncomeFilled
} from "wg-fe-ui";

import FlowProgressBarStepContainer from "./FlowProgressBarStepContainer";
import GoBack from "../assets/icons/GoBack";

const icons = [
  <IconCarFilled color="white" />,
  <IconLookingForCarFilled color="white" />,
  <IconSecondHandCarFilled color="white" />,
  <IconIncomeFilled color="white" />
];

const FLowProgressBar = () => {
  const history = useHistory();

  return (
    <StyledBar>
      <GoBack
        data-test-it="underwriting_goback_nav"
        onClick={() => history.goBack()}
      />

      <ProgressBar>
        {icons.map((icon, key) => {
          return (
            <FlowProgressBarStepContainer icon={icon} key={key} id={key + 1} />
          );
        })}
      </ProgressBar>
    </StyledBar>
  );
};

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
`;

const StyledBar = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 85%;
  margin-bottom: 3rem;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export default FLowProgressBar;
