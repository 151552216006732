import React from "react";
import styled from "styled-components";
import i18n from "../i18n";

const FlowOccupationInformationJobsList = () => {
  return (
    <Ul>
      <Li>
        {i18n.t(
          "Passenger transport (taxi, airport transport, ambulances, etc)"
        )}
      </Li>
      <Li>{i18n.t("New Mobility (carsharing, ridesharing, freefloating)")}</Li>
      <Li>
        {i18n.t(
          "Courier services, parcel services, urgent deliveries, express transport, just-in-time transport"
        )}
      </Li>
      <Li>{i18n.t("Transport of dangerous products (ADR)")}</Li>
      <Li>{i18n.t("Transportation of securities, cash transport")}</Li>
      <Li>{i18n.t("Driving schools")}</Li>
      <Li>{i18n.t("Transport on behalf of third parties")}</Li>
      <Li>{i18n.t("Short term rental")}</Li>
      <Li>{i18n.t("Team sports teams")}</Li>
      <Li>{i18n.t("Rally vehicles")}</Li>
      <Li>
        {i18n.t("Catering, dancing, bar, charm services, games & casino")}
      </Li>
      <Li>
        {i18n.t(
          "Travelling trader, scrap metal dealer, cattle trader, prodigal pedlar"
        )}
      </Li>
    </Ul>
  );
};

const Ul = styled.ul`
  list-style: circle;
  padding-left: 2rem;
  @media (max-width: 768px) {
    margin-left: 2rem;
    width: 100%;
  }
`;

const Li = styled.li`
  font-size: 1.4rem;
  margin-top: 0.65rem;
  line-height: 1.1;
  @media (max-width: 768px) {
    margin-top: 0.9rem;
  }
`;

export default FlowOccupationInformationJobsList;
