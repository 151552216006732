export default [
  {
    id: "civil_liability",
    title: "Civil liablity",
    ipidLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_elvas_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_elvas_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_elvas_nl.pdf"
    },
    termsLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_nl.pdf"
    },
    mandatory: true,
    helper: "civil_liability_description"
  },
  {
    id: "legal",
    title: "Legal aid",
    ipidLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/legal/euromex/ipid_euromex_car_legal_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/legal/euromex/ipid_euromex_car_legal_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/legal/euromex/ipid_euromex_car_legal_nl.pdf"
    },
    termsLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/legal/euromex/terms_euromex_car_legal_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/legal/euromex/terms_euromex_car_legal_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/legal/euromex/terms_euromex_car_legal_nl.pdf"
    },
    mandatory: true,
    helper: "legal_aid_description"
  },
  {
    id: "omnium",
    title: "Omnium",
    ipidLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_elvas_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_elvas_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_elvas_nl.pdf"
    },
    termsLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_nl.pdf"
    },
    mandatory: false,
    helper: "omnium_description"
  },
  {
    id: "driver",
    title: "Driver insurance",
    ipidLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_driver_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_driver_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_driver_nl.pdf"
    },
    termsLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_nl.pdf"
    },
    mandatory: false,
    helper: "driver_description"
  },
  {
    id: "assistance_vehicle_person",
    title: "Assistance vehicle person",
    ipidLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_assistance_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_assistance_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/ipid_allianz_car_plan_assistance_nl.pdf"
    },
    termsLink: {
      nl:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_assistance_nl.pdf",
      fr:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_assistance_fr.pdf",
      en:
        "http://files.wegroup.be/documents/insurances/car/allianz/terms_allianz_car_plan_assistance_nl.pdf"
    },
    mandatory: false,
    helper: "assistance_description"
  }
];
