import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { CheckBox } from "wg-fe-ui";

import TurningCheckboxArrow from "./TurningCheckboxArrow";

const CollapsibleCheckbox = ({
  id,
  onChange = () => {},
  title,
  shortDiscription,
  longDiscription,
  children,
  value = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSelected, setIsSelected] = useState(value);

  useEffect(() => {
    onChange({ name: id, value: isSelected });
  }, [isSelected]);

  useEffect(() => {
    setIsSelected(value);
  }, [value]);

  function handleCheckboxClick(e) {
    // Check if you clicked an Anchor-tag in the discription
    const event = e.nativeEvent;
    if (event) {
      if (event.target.tagName === "A") return;
    }

    setIsSelected(!isSelected);
  }

  return (
    <Container>
      <CheckWrapper isCollapsed={isCollapsed}>
        <CheckBox
          name={id}
          checked={isSelected}
          onChange={handleCheckboxClick}
        />
        <CheckBoxTitleAndDiscription onClick={handleCheckboxClick}>
          <Title>{title}</Title>
          <ShortDiscription
            dangerouslySetInnerHTML={{ __html: shortDiscription }}
          />
        </CheckBoxTitleAndDiscription>
        {longDiscription || children ? (
          <StyledTurningCheckboxArrow
            onChange={val => setIsCollapsed(!!(val === "checked"))}
          />
        ) : null}
      </CheckWrapper>
      {children ? (
        <CollapsedContent isCollapsed={isCollapsed}>
          {children}
        </CollapsedContent>
      ) : (
        <CollapsedContent
          isCollapsed={isCollapsed}
          dangerouslySetInnerHTML={{ __html: longDiscription }}
        />
      )}
    </Container>
  );
};

const CollapsedContent = styled.div`
  font-size: 1.4rem;
  transition: transform 0.1s ease-out, padding-top 0.3s ease-out;
  line-height: 1.2;
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          background: ${({ theme }) => theme.ui.background};
          padding: 2rem;
          height: auto;
          opacity: 1;
          transform: scale(1, 1);
        `
      : css`
          overflow: hidden;
          opacity: 0;
          height: 0;
          transform: scale(1, 0);
          transform-origin: center top;
        `};
`;

const StyledTurningCheckboxArrow = styled(TurningCheckboxArrow)`
  margin-left: auto;
`;

const ShortDiscription = styled.p`
  font-size: 1.4rem;
  line-height: 1.2;
`;

const Title = styled.h2`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const CheckBoxTitleAndDiscription = styled.label`
  margin-left: 0.5rem;
  width: 85%;
  cursor: pointer;
`;

const Container = styled.div``;

const CheckWrapper = styled.div`
  transition: all 0.3s ease;
  display: flex;
  padding: 1rem 2rem;
  background: ${({ theme, isCollapsed }) =>
    isCollapsed ? theme.ui.background : "white"};
  align-items: flex-start;
`;

export default CollapsibleCheckbox;
