import { stringLength } from "./stringService";

/**
 * Parses date from object format to Date
 * @param {object} date {year, month, day, hour, minute, second}
 */
export const parseIncDate = (date = { year: 0, month: 0, day: 0 }) => {
  const parsedDate = new Date(
    stringLength(date.year) === 4 ? date.year : undefined,
    date.month - 1,
    date.day,
    date.hour || 0,
    date.minute || 0,
    date.second || 0
  );
  return isValidDate(parsedDate) ? parsedDate : {};
};
/**
 * Parses date to the locale string separated by '/'
 * @param {object} date {year, month, day, hour, minute, second}
 */

export const parseIncDateToString = (date = {}) => {
  const parsedDate = parseIncDate(date);
  if (isValidDate(parsedDate)) {
    return parsedDate.toLocaleDateString().replace(/\./g, "/");
  } else {
    return "";
  }
};

/**
 * Parses date to an object { day: DD, month: MM, year: YYYY }
 * @param {string} date DD/MM/YYYY
 */

export function parseIncDateToObject(string) {
  if (string) {
    const [day, month, year] = string.split("/");
    if (day && month && year) {
      return { day, month, year };
    }
  }

  return {};
}

/**
 * Parses object to an new date Sun Dec 17 1995 03:24:00 GMT...
 * @param {object} date { day: DD, month: MM, year: YYYY }
 */

export function parseObjectToNewDate(obj) {
  return new Date(obj.year, obj.month - 1, obj.day);
}

/**
 * Checkes if the passed date is an dateObject
 * @param {object} "[object Date]""
 */

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
