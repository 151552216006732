import React from "react";
import styled from "styled-components";
import { func } from "prop-types";
import i18n from "../i18n";

const FlowPolicyHolderPersonRadio = ({ checked, personType }) => {
  const handleRadioInput = e => {
    const value = e.currentTarget.value;
    personType(value);
  };

  return (
    <InputContainer>
      <p>{i18n.t("You are a")}:</p>
      <StyledInput
        onChange={e => handleRadioInput(e)}
        type="radio"
        name="person_type"
        checked={checked === "natural"}
        id="natural"
        value="natural"
      />
      <StyledInputLabel htmlFor="natural">
        {i18n.t("Private individual")}
      </StyledInputLabel>
      <StyledInput
        onChange={e => handleRadioInput(e)}
        type="radio"
        checked={checked === "legal"}
        name="person_type"
        id="legal"
        value="legal"
      />
      <StyledInputLabel htmlFor="legal">
        {i18n.t("Legal entity")}
      </StyledInputLabel>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  width: 100%;

  & p {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.typo.subTitle};
  }

  & label + input + label {
    margin-left: 1rem;
  }
`;

const StyledInput = styled.input`
  display: none;

  &:checked + label {
    min-width: 30%;
    width: 48.5%;
    background-color: ${({ theme }) => theme.brand.lightest};
    border-color: ${({ theme }) => theme.brand.primary};
    color: ${({ theme }) => theme.brand.primary};
    /* @media (max-width: 1130px) {
      width: 48%;
    } */
  }
`;

const StyledInputLabel = styled.label`
  border: 0.1rem solid rgb(204, 204, 204);
  color: rgb(80, 80, 80);
  border-style: solid;
  border-image: initial;
  border-radius: 0.2rem;
  height: 6rem;
  width: 48.5%;
  min-width: 30%;
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  /* @media (max-width: 1130px) {
    width: 48%;
  } */
`;

FlowPolicyHolderPersonRadio.propTypes = {
  personType: func.isRequired
};

export default FlowPolicyHolderPersonRadio;
