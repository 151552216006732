export default [
  {
    title: "drivers-information",
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: "occupation-information"
    }
  },
  {
    title: "occupation-information",
    step: 1,
    subStep: 2,
    nextRoutes: {
      1: "search-vehicle",
      2: "acceptance-conditions"
    }
  },
  {
    title: "acceptance-conditions",
    step: 1,
    subStep: 3,
    nextRoutes: {}
  },
  {
    title: "search-vehicle",
    step: 2,
    subStep: 0,
    nextRoutes: {
      1: "car-information"
    }
  },
  {
    title: "car-information",
    step: 2,
    subStep: 1,
    nextRoutes: {
      1: "price-calculator"
    }
  },
  {
    title: "price-calculator",
    step: 3,
    subStep: 0,
    nextRoutes: {
      1: "policyholder-information"
    }
  },
  {
    title: "policyholder-information",
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: "overview"
    }
  },
  {
    title: "overview",
    step: 4,
    subStep: 0,
    nextRoutes: {
      1: "generating-documents"
    }
  },
  {
    title: "generating-documents",
    step: 4,
    subStep: 1,
    nextRoutes: {}
  }
];
