import React, { useState, useEffect } from "react";
import FlowPolicyHolderInfoFormPrivate from "./FlowPolicyHolderInfoFormPrivate.jsx";
import FlowPolicyHolderInfoFormLegal from "./FlowPolicyHolderInfoFormLegal.jsx";
import FlowPolicyHolderPersonRadio from "./FlowPolicyHolderPersonRadio.jsx";
import { patchStorage } from "../helpers/storeService";
import { useParams } from "react-router";

const FlowPolicyHolderInfoForm = ({ retrieveSubmit, defaultValues }) => {
  const [personType, setPersonType] = useState();
  const { id } = useParams();

  const formSubmitHandler = val => {
    retrieveSubmit(val);
  };

  useEffect(() => {
    if (defaultValues !== undefined) {
      const isPrimary = defaultValues.primary === "yes";
      if (isPrimary) {
        setPersonType("natural");
      } else {
        setPersonType();
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    patchStorage({ personType: personType }, id);
  }, [personType]);

  return (
    <>
      <FlowPolicyHolderPersonRadio
        personType={val => setPersonType(val)}
        checked={personType}
      />
      {personType === "natural" ? (
        <FlowPolicyHolderInfoFormPrivate
          defaultValues={defaultValues}
          sendSubmit={formSubmitHandler}
        />
      ) : null}
      {personType === "legal" ? (
        <FlowPolicyHolderInfoFormLegal
          defaultValues={defaultValues}
          sendSubmit={formSubmitHandler}
        />
      ) : null}
    </>
  );
};

export default FlowPolicyHolderInfoForm;
