export let loggedIn = false;

export function setLoggedIn(param) {
  loggedIn = param;
}

export function formattedLoginExeptions(msg, status) {
  const passwordMismatch = ["PASSWORD_MISMATCH", "PASSWORD_VALIDATION"];
  const environmentMismatch = ["ENVIRONMENT_MISMATCH"];
  const userExpired = ["USER_EXPIRED"];
  const tryAgainLater = [
    "AUTHENTICATION_EXPIRED",
    "AUTHENTICATION_FAILED",
    "NOT_AUTHENTICATED",
    "REFRESH_TOKEN_INCORRECT"
  ];
  const userNotFound = ["USER_BLACKLISTED", "USER_NOT_FOUND"];

  let responseMsg = "";

  if (status === 400) {
    if (passwordMismatch.includes(msg)) responseMsg = "passwordMismatch";
  }

  if (status === 401) {
    if (passwordMismatch.includes(msg)) responseMsg = "passwordMismatch";
    if (environmentMismatch.includes(msg)) responseMsg = "environmentMismatch";
    if (userExpired.includes(msg)) responseMsg = "userExpired";
    if (tryAgainLater.includes(msg)) responseMsg = "tryAgainLater";
    if (userNotFound.includes(msg)) responseMsg = "userNotFound";
  }

  if (status === 404) {
    if (userNotFound.includes(msg)) responseMsg = "userNotFound";
  }

  return responseMsg;
}
