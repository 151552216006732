import React from "react";
import { Route, Switch } from "react-router-dom";
import { retrieveStorage } from "../helpers/storeService";

import FlowDriversInformation from "./FlowDriversInformation";
import FlowOccupationInformation from "./FlowOccupationInformation";
import FlowAcceptanceCondition from "./FlowAcceptanceCondition";
import FlowSearchForVehicle from "./FlowSearchForVehicle";
import FlowCarInformation from "./FlowCarInformation";
import FlowPriceCalculator from "./FlowPriceCalculator";
import FlowPolicyHolderInfo from "./FlowPolicyHolderInfo";
import FlowOverview from "./FlowOverview";
import FlowGeneratingDocuments from "./FlowGeneratingDocuments";

import Login from "../pages/Login";

import FlowRouterRedirect from "../components/FlowRouterRedirect";

const FlowRouter = () => {
  const { id } = retrieveStorage() || "";

  return (
    <Switch>
      <Route path="/verify-user" component={Login} />
      <Route
        path="/session/drivers-information/:id"
        component={FlowDriversInformation}
      />
      <Route
        path="/session/occupation-information/:id"
        component={FlowOccupationInformation}
      />
      <Route
        path="/session/acceptance-conditions/:id"
        component={FlowAcceptanceCondition}
      />
      <Route
        path="/session/search-vehicle/:id"
        component={FlowSearchForVehicle}
      />
      <Route
        path="/session/car-information/:id"
        component={FlowCarInformation}
      />
      <Route
        path="/session/price-calculator/:id"
        component={FlowPriceCalculator}
      />
      <Route
        path="/session/policyholder-information/:id"
        component={FlowPolicyHolderInfo}
      />
      <Route
        path="/session/generating-documents/:id"
        component={FlowGeneratingDocuments}
      />
      <Route path="/session/overview/:id" component={FlowOverview} />
      <FlowRouterRedirect
        from="/session"
        to="/session/drivers-information"
        id={id}
        loginPath="/verify-user"
      />
    </Switch>
  );
};

export default FlowRouter;
