import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";
import { differenceInYears } from "date-fns";

import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import FlowOccupationInformationQuestion from "../components/FlowOccupationInformationQuestion.jsx";

import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";
import { parseIncDateToObject, parseIncDate } from "../helpers/dateService";

const FlowOccupationInformation = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, []);

  function handleSubmit(values) {
    patchStorage(values, id);
    isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(2);
  }

  function getYearDiff(date1, date2) {
    return Math.abs(date2.getFullYear() - date1.getFullYear());
  }

  function isPersonAccepted() {
    const conditionsArray = [];
    const { data } = retrieveStorageById(id) || {};
    const {
      birth,
      numberOfAccidentsLastFiveYears,
      specialEmployment,
      issueLicenseDate,
    } = data || {};

    const age = returnPersonAge(birth);
    conditionsArray.push(age >= 23);

    const accidents = parseInt(numberOfAccidentsLastFiveYears);
    conditionsArray.push(accidents <= 2);

    conditionsArray.push(specialEmployment === "no");

    const today = new Date();
    const licenseDate = new Date(
      issueLicenseDate.split("/")[2],
      issueLicenseDate.split("/")[1] - 1,
      issueLicenseDate.split("/")[0]
    );
    const licenseDateAmountOfYears = getYearDiff(licenseDate, today);
    conditionsArray.push(age >= 30 || licenseDateAmountOfYears >= 2);

    return !conditionsArray.includes(false);
  }

  function returnPersonAge(birth) {
    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseIncDate(dateObject);
    return differenceInYears(new Date(), birthDate);
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(`Are you practicing one of the following professions`)}
      />
      <RightSplit>
        <FlowOccupationInformationQuestion
          handleFormValues={handleSubmit}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 5rem;
  }
  @media (max-width: 601px) {
    padding: 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowOccupationInformation;
