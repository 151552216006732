import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { func, string, number } from "prop-types";
import InfoPopup from "./InfoPopup";
import i18n from "../i18n";

import ToggleButton from "./ToggleButton.jsx";
import DownloadButton from "./DownloadButton.jsx";

const FlowPriceCalculatorOption = ({
  disabled,
  title,
  ipidLink,
  termsLink,
  onChange,
  id,
  mandatory,
  price,
  helperText,
}) => {
  const [isSelected, setIsSelected] = useState(mandatory);

  useEffect(() => {
    onChange(id, isSelected);
  }, [isSelected]);

  const civilText =
    "The general terms and conditions Car Plan will be displayed, however Elvas has specific standard warranty extensions that can be found here The specific terms and conditions will be added to your policy";

  var myObj = {
    style: "currency",
    currency: "EUR",
  };


  return (
    <OptionWrapper>
      <ToggleButton
        className="toggle"
        disabled={mandatory || disabled}
        onChange={(toggle) => {
          setIsSelected(toggle);
        }}
        defaultCheck={mandatory || !disabled}
      />
      <OptionContent isSelected={isSelected}>
        <TitleAndPrice>
          <OptionTitle>
            {i18n.t(title)} {mandatory ? `(${i18n.t("mandatory")})` : null}
          </OptionTitle>
          <OptionPrice wide={!parseFloat(price)}>
            {parseFloat(price)
              ? `${price.toLocaleString("nl-BE", myObj)}/${i18n.t("y")}`
              : i18n.t("Not possible if car is older than 5 years")}
          </OptionPrice>
          <InfoPopup title={title} info={helperText} />
        </TitleAndPrice>
        <DownloadContainer>
          <DownloadIpid href={ipidLink[i18n.language]}>IPID</DownloadIpid>
          <DownloadTerms href={termsLink[i18n.language]}>
            {i18n.t("Terms and Conditions")}
          </DownloadTerms>
          {id === "civil_liability" ? (
            <StyledInfoPopup
              title={i18n.t("Terms and Conditions")}
              info={i18n.t(civilText)}
            />
          ) : null}
        </DownloadContainer>
      </OptionContent>
    </OptionWrapper>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  position: absolute;
  margin-top: 0.75rem;
  right: -7.5%;
`;

const DownloadTerms = styled(DownloadButton)`
  font-size: 1.46rem;
  padding: 0;
  width: 23rem;
  margin-left: 2rem;
  @media (max-width: 1140px) {
    font-size: 1.4rem;
    padding-left: 2rem;
    width: 16.5rem;
    margin-left: 0.5rem;
    img {
      margin-left: 0rem;
    }
  }
`;

const DownloadIpid = styled(DownloadButton)`
  padding: 0;
  @media (max-width: 1140px) {
    font-size: 1.4rem;
    padding-left: 2rem;
    min-width: 7rem;
    img {
      margin-left: 0rem;
    }
  }
`;

const TitleAndPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.75rem;
`;

const DownloadContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const OptionContent = styled.div`
  margin-left: 2rem;
  width: 84%;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.6)};
  transition-duration: 0.4s;
  transition-property: opacity;
  @media (max-width: 570px) {
    margin-left: 1rem;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  border-radius: 0.75rem;
  .toggle {
    margin: auto;
  }
  @media (max-width: 570px) {
    margin-bottom: 1.5rem;
  }
`;

const OptionPrice = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.typo.subTitle};
  margin-left: auto;
  width: ${({ wide }) => (wide ? "50%" : "30%")};
  text-align: right;
`;

const OptionTitle = styled.h2`
  width: 60%;
  font-size: 1.8rem;
  word-break: break-word;
  font-weight: 700;
  color: ${({ theme }) => theme.typo.subTitle};
  @media (max-width: 570px) {
    font-size: 1.75rem;
  }
`;

FlowPriceCalculatorOption.defaultProps = {
  onChange: () => {},
  title: "",
  ipidLink: "",
  termsLink: "",
  price: 0,
  helperText: "",
};

FlowPriceCalculatorOption.propTypes = {
  onChange: func,
  title: string,
  ipidLink: string,
  termsLink: string,
  price: number,
  helperText: string,
};

export default FlowPriceCalculatorOption;
