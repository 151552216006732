/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect } from "react";
import { getYear, isFuture, differenceInMonths, format } from "date-fns";

import styled from "styled-components";
import InfoPopup from "./InfoPopup";

import i18n from "../i18n.js";

import CollapsibleCheckbox from "./CollapsibleCheckbox";
import FlowOverviewFormTermination from "./FlowOverviewFormTermination";

import { accaptances } from "../constants/FlowSearchSelectData.js";
import pricingOptions from "../constants/PricingOptions.js";
import Options from "./Options";
import DateInput from './DateInput';
import { patchStorage } from "../helpers/storeService";
import { useParams } from "react-router";

const FlowOverviewForm = ({
  errors,
  values,
  handleChange,
  quote,
  storageData,
}) => {
  const { id } = useParams();
  const [accaptancesArray, setAccaptancesArray] = useState([]);
  const patchedOptions = [];
  const { options, base } = quote || {};
  const allOptions = options.slice();
  allOptions.unshift(base);

  allOptions.map(option => {
    const [pricingOption] = pricingOptions.filter(
      obj => obj.id === option.name
    );

    const patchedOption = {
      id: option.name,
      title: pricingOption.title,
      helper: pricingOption.helper,
      totalPremium: option.total_premium
    };

    patchedOptions.push(patchedOption);
  });

  function handleTerminationDate(teminationDate) {
    const currentYear = getYear(new Date());
    const nextYear = getYear(new Date()) + 1;
    let proposedTermDate = generateNewTerminationDate(
      teminationDate,
      currentYear
    );

    if (!isFuture(proposedTermDate)) {
      proposedTermDate = generateNewTerminationDate(teminationDate, nextYear);
    }

    if (differenceInMonths(proposedTermDate, new Date()) < 3) {
      proposedTermDate = generateNewTerminationDate(teminationDate, nextYear);
    }
    patchStorage(
      { terminationDate: format(proposedTermDate, "dd/MM/yyyy") },
      id
    );

    if (!values.startDate) {
      handleChange({name: 'startDate', value: format(proposedTermDate, "dd/MM/yyyy")});
    }
  }

  function generateNewTerminationDate(teminationDate, proposedYear) {
    const proposedTermDateString = proposedYear
      ? `${teminationDate}/${proposedYear}`
      : teminationDate;
    const [day, month, year] = proposedTermDateString.split("/");
    return new Date(year, month - 1, day);
  }

  function onChange({ name, value }) {
    let arr = [];
    arr = accaptancesArray.filter(val => val.name !== name);
    arr.push({ name, value });
    setAccaptancesArray(arr);
    checkIfCheckoutCanBeEnabled();
  }

  function checkIfCheckoutCanBeEnabled() {
    const shouldEnableCheckout =
      accaptances.length === accaptancesArray.length &&
      !accaptancesArray.map(({ value }) => value).includes(false);
    handleChange({name: 'checklist', value: shouldEnableCheckout});
  }


  useEffect(() => {
    checkIfCheckoutCanBeEnabled();
  }, [accaptancesArray]);

  return (
    <Wrapper>
      <h1>{i18n.t("Chosen Options")}:</h1>
      {patchedOptions.map(({ title, helper, totalPremium }) => (
        <Options
          key={title[i18n.language]}
          title={i18n.t(title)}
          helper={i18n.t(helper)}
          totalPremium={totalPremium}
        />
      ))}
      <FlowOverviewFormTermination
        onTerminationDateChange={handleTerminationDate}
        values={values}
        handleChange={handleChange}
        storageData={storageData}
      />
      <StyledDateInput
        name="startDate"
        value={values.startDate}
        error={errors.startDate}
        onChange={handleChange}
      >
        <StyledDiv>
          <p>{i18n.t(`Start date`)}</p>
          <StyledInfoPopup
            title={i18n.t("Attention")}
            info={i18n.t(
              "Bear in mind that if the car still needs to be registered, a turnaround time must be taken into account"
            )}
          />
        </StyledDiv>
      </StyledDateInput>
      {accaptances.map(({ key, title, short, long }) => (
        <CollapsibleCheckbox
          key={key}
          id={key}
          onChange={value => onChange(value, key)}
          title={`${i18n.t(title)}*`}
          shortDiscription={i18n.t(short)}
          longDiscription={i18n.t(long)}
        />
      ))}
      <Info checklistError={errors.checklist}>* {i18n.t(`mandatory`)}</Info>
      <P>{i18n.t(`You will receive a copy of the documents together with the terms and conditions and the IPID sheet by e-mail`)}</P>
    </Wrapper>
  );
};
export default FlowOverviewForm;

const P = styled.p`
  margin-top: 1rem;
  margin-left: auto;
  text-align: right;
  padding-right: 1.5rem;
`;

const Info = styled.p`
  color: ${({ checklistError }) => (checklistError ? "red" : "inherit")};
  margin-top: 1rem;
  margin-left: auto;
  text-align: right;
  padding-right: 1.5rem;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  p {
    align-self: center;
  }
`;

const StyledDateInput = styled(DateInput)`
  padding: 1rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  height: 2rem;
  line-height: 1.13;
  padding-left: 0.25rem;
  width: 2rem;
  margin-left: auto;
`;

const Wrapper = styled.div`
  h1 {
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 1rem;
    text-align: center;
    text-decoration: underline;
  }
`;
