import debounce from "debounce-promise";
import * as Yup from "yup";
import { isRegisterNrValid } from "../helpers/apiRouterService";
import i18n from "../i18n";

export const string = {
  required: Yup.string().required(i18n.t(`required`)),
  notRequired: Yup.string().nullable(),
};

export const number = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`)),
};

export const price = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`))
    .min(1, i18n.t(`required`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`)),
};

export const telephonenr = {
  required: Yup.string()
    .matches(
      /^[0-9+]{1,3}$|^\+32[0-9]{8,9}$/,
      i18n.t(`Please enter a valid telephone number`)
    )
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .nullable()
    .matches(
      /^[0-9+]{1,3}$|^\+32[0-9]{8,9}$/,
      i18n.t(`Please enter a valid telephone number`)
    ),
};

export const date = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
    i18n.t(`not a valid date`)
  )
  .required(i18n.t(`required`));

export const dateWithOutYear = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/,
    i18n.t(`not a valid date`)
  )
  .required(i18n.t(`required`));

export const firstName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const lastName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const companyName = Yup.string()
  .required(i18n.t(`required`))
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ_& ]{1,50}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const cbe = {
  required: Yup.string()
    .matches(/0[0-9]{3}.[0-9]{3}.[0-9]{3}/, i18n.t(`incorrect format`))
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .nullable()
    .matches(/0[0-9]{3}.[0-9]{3}.[0-9]{3}/, i18n.t(`incorrect format`)),
};

export const email = Yup.string()
  .email("email")
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const password = Yup.string()
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const year = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
};

export const licensePlate = Yup.string()
  .matches(/^[A-Za-z0-9 -]{1,20}$/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`));

export const vinNumber = Yup.string()
  .matches(/^[A-Z0-9]{17,17}$/, i18n.t(`incorrect format`))
  .required(i18n.t(`required`));

export const captcha = Yup.string(i18n.t(`required`))
  .matches(/[0-9a-zA-Z_-]{40}/)
  .nullable()
  .required(i18n.t(`required`));

export const website = Yup.string(i18n.t(`required`))
  .matches()
  .nullable()
  .required(i18n.t(`required`));

export const numberplate = Yup.string(i18n.t(`required`));

const handleRegisterNrValidation = async (nr) => {
  // nr is nullable
  if (nr === null || nr === undefined || nr.length === 0) return true;
  // check nr
  const [, status] = await isRegisterNrValid(nr);
  return status === 200;
};
const debounceRegisterNr = debounce(handleRegisterNrValidation, 1000);

export const nationalRegisterNr = Yup.string(i18n.t(`required`))
  .test("national_register_nr", i18n.t("Invalid format"), async (nr) => {
    return await debounceRegisterNr(nr);
  })
  .required(i18n.t(`required`));

export const street = Yup.string()
  .required(i18n.t(`required`))
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`));

export const zipcode = Yup.number()
  .typeError(i18n.t(`not a number`))
  .required(i18n.t(`required`))
  .min(1000, i18n.t(`too short`))
  .max(9999, i18n.t(`too long`));

export const housenr = Yup.string()
  .required(i18n.t(`required`))
  .matches(/^[a-z0-9\- /]{1,20}$/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`));

export const boxNr = Yup.string()
  .matches(/[a-zA-Z0-9\- /]{1,20}/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`))
  .nullable();

export const city = Yup.string()
  .required(i18n.t(`required`))
  .matches(
    /^[A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`));
