/* eslint-disable no-unexpected-multiline */
/* eslint-disable no-extra-boolean-cast */
import React from "react";
import elvasLogo from "../assets/images/elvas_logo.svg";
import styled from "styled-components";
import i18n from "../i18n";
// import { patchStorage } from "../helpers/storeService";
// import { useParams } from "react-router";
import UploadButtonIcon from "./UploadButtonIcon";
import DocumentIcon from "./DocumentIcon";
// import { getNestedObject } from "../helpers/objectService";

const FlowOverviewDriversInformation = ({ errors, handleChange, values, storageData, setFilledReceipt, filledReceipt, filledRegistrationForm, setFilledRegistrationForm }) => {
  const hasOmnium = storageData.pricingQuery.includes("option=omnium");

  const {
    firstName,
    lastName,
    birth,
    company_name,
    cbe,
    driverLicenseType,
    issueLicenseDate,
    numberOfAccidentsLastFiveYears,
    brand,
    model,
    kWh,
    invoice_value,
    options,
    vinNumber,
    licensePlate,
    naturalPersonBirth
  } = storageData || {};

  var myObj = {
    style: "currency",
    currency: "EUR"
  };

  return (
    <DriversInformation>
      <DriverInfoWrapper>
        <ElvasLogo src={elvasLogo} alt="" />
        <DriverInfoHeader>{i18n.t("Main driver")}</DriverInfoHeader>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Date of birth")}</DriverInfoLabel>
          {birth}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("License issue date")}</DriverInfoLabel>
          {issueLicenseDate}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("License type")}</DriverInfoLabel>
          {i18n.t(driverLicenseType)}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>
            {i18n.t("Number of accidents (<5yrs)")}
          </DriverInfoLabel>
          {numberOfAccidentsLastFiveYears}
        </DriverInfoListItem>
        <DriverInfoHeader>{i18n.t("Insurance provider")}</DriverInfoHeader>
        {company_name ? (
          <>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Company name")}</DriverInfoLabel>
              {company_name}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>
                {i18n.t("Company registration number")}
              </DriverInfoLabel>
              {cbe}
            </DriverInfoListItem>
          </>
        ) : (
          <>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Name")}</DriverInfoLabel>
              {firstName} {lastName}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Date of birth")}</DriverInfoLabel>
              {naturalPersonBirth}
            </DriverInfoListItem>
          </>
        )}
        <DriverInfoHeader>{i18n.t("Vehicle information")}</DriverInfoHeader>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Brand")}</DriverInfoLabel>
          {brand}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Model")}</DriverInfoLabel>
          {model}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Battery")}</DriverInfoLabel>
          {kWh} kWh
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Invoice value")}</DriverInfoLabel>
          {parseFloat(invoice_value).toLocaleString("nl-BE", myObj)} ex.BTW
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Options value")}</DriverInfoLabel>
          {options
            ? parseFloat(options).toLocaleString("nl-BE", myObj)
            : (0).toLocaleString("nl-BE", myObj)}{" "}
          ex.BTW
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("VIN")}</DriverInfoLabel>
          {vinNumber}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("License plate")}</DriverInfoLabel>
          {licensePlate}
        </DriverInfoListItem>
      </DriverInfoWrapper>
      <ButtonContainer>
        <Inputwrapper>
          <InputLabel className="signUpload" htmlFor="sign-upload">
            {!!values.registrationForm || filledRegistrationForm ? <DocumentIcon /> : <UploadButtonIcon />}
            <p>{values.registrationForm || filledRegistrationForm ? i18n.t('Replace registration form') : i18n.t('Upload registration form')}</p>
          </InputLabel>
          <ErrorMsg className="error">{errors.registrationForm}</ErrorMsg>
        </Inputwrapper>
        <StyledInput
          id="sign-upload"
          name="sign-upload"
          onChange={e => {
            setFilledRegistrationForm(false);
            return handleChange({name: 'registrationForm', value: e.target.files});
          }}
          accept="file/pdf, file/docx, file/doc, file/jpg, file/png, file/heic, file/heif"
          type="file"
        />
        {hasOmnium ? (
          <>
            <Inputwrapper>
              <InputLabel htmlFor="receipt-upload">
                {!!values.receipt || filledReceipt ? <DocumentIcon /> : <UploadButtonIcon />}
                <p>{values.receipt || filledReceipt ? i18n.t('Replace uploaded receipt') : i18n.t('Upload receipt')}</p>
              </InputLabel>
              <ErrorMsg>{errors.receipt}</ErrorMsg>
            </Inputwrapper>
            <StyledInput
              id="receipt-upload"
              name="receipt-upload"
              onChange={e => {
                setFilledReceipt(false);
                return handleChange({name: 'receipt', value: e.target.files});
              }}
              accept="file/pdf, file/docx, file/doc, file/jpg, file/png, file/heic, file/heif"
              type="file"
            />
          </>
        ) : null}
      </ButtonContainer>
    </DriversInformation>
  );
};

const ButtonContainer = styled.div`
  align-self: center;
  margin-top: auto;
  max-width: 35rem;
  width: 80%;
`;

const InputLabel = styled.label`
  align-items: center;
  background-color: #188bee;
  border: 0;
  border-radius: 0.5rem;
  color: white;
  justify-content: space-evenly;
  word-break: keep-all;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  height: 5rem;
  line-height: 2rem;
  min-width: 14rem;
  padding: 1rem 0.4rem;
  padding-right: 5rem;
  text-align: center;
  p {
    width: 30rem;
  }
  &.signUpload {
    margin-top: 3.5rem;
  }
`;

const Inputwrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledInput = styled.input`
  display: none;
  opacity: 0;
`;

const ErrorMsg = styled.p`
  color: #f74040;
  font-size: 1.2rem;
  height: 1.5rem;
  margin: 1rem 0;
  text-align: end;
`;

const ElvasLogo = styled.img`
  display: flex;
  margin: 0 auto 2rem auto;
  width: 20rem;
`;

const DriversInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DriverInfoHeader = styled.li`
  font-size: 18px;
  font-weight: bold;
  padding: 1rem 0;
  padding-top: 2rem;
  text-align: center;
  text-transform: uppercase;
`;
const DriverInfoWrapper = styled.ul`
  display: grid;
  width: 80%;
  max-width: 35rem;
  margin: 0 auto;
`;

const DriverInfoLabel = styled.label`
  text-align: left;
`;

const DriverInfoListItem = styled.li`
  display: grid;
  grid-template-columns: 10vw auto;
  text-align: right;
  padding: 0.5rem 0;
  @media (max-width: 570px) {
    font-size: 14px;
  }
`;

export default FlowOverviewDriversInformation;
