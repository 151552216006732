import React from "react";
import Main from "./pages/Main.js";
import GlobalStyle from "./resetStyling";
import { ThemeContextProvider } from "./contexts/ThemingContext";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import "./i18n";

if (process.env.NODE_ENV !== "development") {
  LogRocket.init("7l7lpz/elvas-group-casier");
  setupLogRocketReact(LogRocket);
}

function App() {
  return (
    <div className="App">
      <ThemeContextProvider>
        <GlobalStyle />
        <Helmet>
          <html lang={i18next.language} />
        </Helmet>
        <Main />
      </ThemeContextProvider>
    </div>
  );
}

export default App;
