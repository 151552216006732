/* 
  @accept array of objects

  [
    {test: test},
    {test: test},
    {test: test},
    {test: test},
  ]
*/

export const removeDuplicates = arr => {
  const uniq = new Set(arr.map(e => JSON.stringify(e)));
  return Array.from(uniq).map(e => JSON.parse(e));
};

/* 
  @accept object, arrary of strings

  nestedObj = {
    id: 101,
    email: 'jack@dev.com',
    personalInfo: {
        name: 'Jack',
        address: {
            line1: 'westwish st',
            line2: 'washmasher',
            city: 'wallas',
            state: 'WX'
        }
    }
  }

  pathArr = ['personalInfo', 'name'] 

  this safely's returns 'Jack'
*/

export const getNestedObject = (nestedObj, pathArr) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    nestedObj,
  );
};

/* 
  @accept object

  returns true if object is empty
*/

export const isObjectEmpty = obj => {
  return Object.entries(obj).length === 0;
};

/* 
  @accept object

  returns true if object is an object
*/

export const isObject = obj => {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
};
