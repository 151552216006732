import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";
// import useForm from "../hooks/useForm";

import { SearchSelectInput, ActionButton } from "wg-fe-ui";
import {
  retrieveCarBrandsV2,
  retrieveCarModelsV2,
  retrieveCarVersionsV2,
} from "../helpers/apiRouterService";
import SearchVersionList from "./SearchVersionList";
import VehicleFormCategorySelector from "./VehicleFormCategorySelector";

const FlowSearchForVehicleForm = ({
  defaultValues,
  handleSubmit,
  handleManual,
  handleBrandLogo,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("PASSENGER_CAR");
  const [selectedBrand, setSelectedBrand] = useState();
  const [retrievedBrands, setRetrievedBrands] = useState({});
  const [retrievedModels, setRetrievedModels] = useState({});
  const [selectedModel, setSelectedModel] = useState();
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [versionList, setVersionList] = useState([]);
  const [filterdversionList, setFilterdVersionList] = useState([]);
  const [brandLogoOptions, setBrandLogoOptions] = useState({});

  useEffect(() => {
    setDefaultValues();
    handleBrandOptions();
  }, [defaultValues]);

  useEffect(() => {
    setSelectedBrand();
    setSelectedVersion();
    setSelectedModel();
    // setFilterdVersionList([]);
    // handleBrandOptions();
  }, [selectedCategory]);

  useEffect(() => {
    handleBrandLogo(brandLogoOptions[selectedBrand]);
    handleModelOptions();
  }, [selectedBrand]);

  useEffect(() => {
    if (!selectedModel) {
      setFilterdVersionList([]);
      return;
    }
    handleVersions();
  }, [selectedModel]);

  function setDefaultValues() {
    if (defaultValues.brand) setSelectedBrand(defaultValues.brand);
  }

  async function handleBrandOptions(q) {
    const [resp, status] = await retrieveCarBrandsV2(
      selectedCategory,
      q ? "motor_type=ELE&q=" + q : "motor_type=ELE"
    );

    if (status !== 200) return;
    let brandLogos = {};
    let brandOptions = [];

    Object.values(resp.items).forEach((brands) => {
      brandOptions.push({
        label: brands.brand.name,
        value: brands.brand.key,
      });
      brandLogos[brands.brand.key] = brands.brand.logo_url;
    });

    setRetrievedBrands(resp);
    if (selectedBrand || defaultValues.brand) {
      handleBrandLogo(resp[selectedBrand || defaultValues.brand]);
    }

    setBrandLogoOptions(brandLogos);

    return brandOptions;
  }

  async function handleModelOptions(q) {
    if (!selectedBrand) return;
    const [resp, status] = await retrieveCarModelsV2(
      selectedCategory,
      selectedBrand,
      q ? "motor_type=ELE&q=" + q : "motor_type=ELE"
    );

    if (status !== 200) return;
    const modelsValues = [];

    Object.values(resp.items).forEach((models) => {
      modelsValues.push({
        label: `${models.model.model}`,
        value: models.model.id,
      });
    });

    setRetrievedModels(modelsValues);

    return modelsValues;
  }

  async function handleVersions() {
    if (!selectedBrand || !selectedModel) return;
    const [resp, status] = await retrieveCarVersionsV2(
      selectedCategory,
      selectedBrand,
      selectedModel,
      "motor_type=ELE"
    );

    if (status !== 200) {
      setVersionList([]);
    } else {
      filterPowerList(resp.items && resp.items.length > 0 ? resp.items : []);
      filterYearList(resp.items && resp.items.length > 0 ? resp.items : []);
      setVersionList(resp.items && resp.items.length > 0 ? resp.items : []);
      setFilterdVersionList(
        resp.items && resp.items.length > 0 ? resp.items : []
      );
    }
  }

  function filterPowerList(versionList) {
    const powerArr = [];

    versionList.map((versionObj) => {
      powerArr.push(versionObj.version.kw);
    });
  }

  function filterYearList(versionList) {
    const yearArr = [];

    versionList.map((versionObj) => {
      yearArr.push(versionObj.version.model_year);
    });

    const filteredYearArr = [...new Set(yearArr)].sort();

    setYearList(
      filteredYearArr.map((year) => {
        return { label: year, value: year };
      })
    );
  }

  useEffect(() => {
    handleVersionFiltering();
  }, [selectedYear]);

  function handleVersionFiltering() {
    setFilterdVersionList(
      versionList.filter((versionObj) =>
        selectedYear ? versionObj.version.model_year === selectedYear : true
      )
    );
  }

  function handleSelectValue(object, inputName) {
    let key = "brand";
    if (inputName === "brand") key = selectedBrand;
    if (inputName === "model") key = selectedModel;
    const [selectValue] = Object.keys(object).filter((k) => k === key);
    if (!selectValue) return undefined;
    return { label: selectValue, value: selectValue };
  }
  const SelectPlaceholder = i18n.t("Choose your option");
  const noOptionMessage = i18n.t("No option");

  return (
    <GridContainer>
      <VehicleFormCategorySelector
        onChange={(e) => setSelectedCategory(e.currentTarget.value)}
        value={selectedCategory}
      />
      <SelectInput
        key={selectedCategory}
        async
        name="brand"
        onSelected={({ value }) => {
          setSelectedBrand(value);
        }}
        loadOptions={handleBrandOptions}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
        value={
          selectedBrand ? handleSelectValue(retrievedBrands, "brand") : null
        }
      >
        {i18n.t("Brand")}
      </SelectInput>
      <SelectInput
        name="model"
        disabled={!selectedBrand}
        onSelected={({ value }) => {
          setSelectedModel(value);
        }}
        options={retrievedModels}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
        value={
          retrievedModels ? handleSelectValue(retrievedModels, "model") : null
        }
      >
        {i18n.t("Model")}
      </SelectInput>
      <SelectInput
        name="modelYear"
        disabled={!selectedModel}
        onSelected={({ value }) => {
          setSelectedYear(value);
        }}
        options={yearList}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
        // value={handleSelectValue(retrievedBrands, "brand")}
      >
        {i18n.t("Model year")}
      </SelectInput>
      <SearchVersionList
        filterdversionList={filterdversionList}
        disabled={!selectedModel}
        onSelected={(data) => setSelectedVersion(data)}
      />
      <ButtonContainer>
        <ManualLink
          level="secondary"
          onClick={(e) => {
            e.preventDefault();
            handleManual();
          }}
        >
          {i18n.t("I cannot find my car")}
        </ManualLink>
        <ActionButton
          onClick={() =>
            handleSubmit(selectedVersion, selectedBrand, selectedModel)
          }
        >
          {i18n.t("Next")}
        </ActionButton>
      </ButtonContainer>
    </GridContainer>
  );
};

const ManualLink = styled.a`
  color: ${({ theme }) => theme.typo.subTitle};
  margin-left: 0rem;
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-gap: 1.6rem;
`;

const SelectInput = styled(SearchSelectInput)`
  min-height: unset;
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;

export default FlowSearchForVehicleForm;
