import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import { TextInput, SearchSelectInput, MaskedInput } from "wg-fe-ui";
import DateInput from "../components/DateInput";
import { object, func } from "prop-types";
import { primaryOptions as primaryOptionsConstants } from "../constants/FlowSearchSelectData";
import {
  string,
  email,
  firstName,
  lastName,
  date,
  licensePlate,
  vinNumber,
  street,
  city,
  zipcode,
  housenr,
  boxNr,
  nationalRegisterNr,
  telephonenr,
} from "../constants/validationSchemas";
import useForm from "../hooks/useForm";
import * as Yup from "yup";
import { useParams } from "react-router";
import { retrieveStorageById } from "../helpers/storeService.js";
import FlowPolicyHolderInfoFormDefaultInputs from "./FlowPolicyHolderInfoFormDefaultInputs";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

const FlowPolicyHolderInfoFormPrivate = ({ sendSubmit, defaultValues }) => {
  const { id } = useParams();
  const { data } = retrieveStorageById(id) || {};
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const validationSchema = Yup.object().shape({
    email: email,
    firstName: firstName,
    lastName: lastName,
    naturalPersonBirth: date,
    telephonenr: telephonenr.required.test(
      "Phone number",
      i18n.t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, "");
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),
    owns_car: string.required,
    streetName: street,
    municipalityName: city,
    postalCode: zipcode,
    streetNumber: housenr,
    boxNumber: boxNr,
    licensePlate: licensePlate,
    vinNumber: vinNumber,
    national_register_nr: nationalRegisterNr,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).map((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
  }, [defaultValues]);

  const isDriverInsuree = data.primary ? true : false;

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  const handleForm = async (e) => {
    e.preventDefault();
    handleSubmit(sendSubmit);
  };

  const SelectPlaceholder = i18n.t("Choose your option");

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setPrimaryOptions(
      primaryOptionsConstants.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function handleSelectValue(object, inputName) {
    if (!data[inputName]) return "";
    const key = data[inputName];

    return object.filter(({ value }) => value === key);
  }

  return (
    <form onSubmit={handleForm}>
      <StyledSearchSelectInput
        onSelected={(val) => handleSelectChange(val, "owns_car")}
        name="owns_car"
        error={errors.owns_car}
        value={handleSelectValue(primaryOptions, "owns_car")}
        options={primaryOptions}
        placeholder={SelectPlaceholder}
      >
        {i18n.t(
          "Is the usual driver a person other than the owner of the vehicle"
        )}{" "}
        *
      </StyledSearchSelectInput>
      <MaskedInput
        error={errors.national_register_nr}
        mask="99.99.99-999.99"
        name="national_register_nr"
        onChange={(e) => {
          if (e.value === "  .  .  -   .  " || e.value === "") {
            e.value = undefined;
          }
          handleChange(e);
        }}
        type="text"
        value={values.national_register_nr}
        placeholder="  .  .  -   .  "
      >
        {i18n.t("National registration number")} *
      </MaskedInput>
      <FlexWrapper>
        <TextInput
          name="firstName"
          error={errors.firstName}
          onChange={(val) => handleChange(val)}
          value={values.firstName}
        >
          <NameInput>{i18n.t("First name")} *</NameInput>
        </TextInput>
        <TextInput
          name="lastName"
          error={errors.lastName}
          onChange={(val) => handleChange(val)}
          value={values.lastName}
        >
          <NameInput>{i18n.t("Last name")} *</NameInput>
        </TextInput>
        <DateInput
          error={errors.naturalPersonBirth}
          name="naturalPersonBirth"
          onChange={handleChange}
          value={isDriverInsuree ? values.birth : values.naturalPersonBirth}
        >
          {i18n.t("Date of birth")} *
        </DateInput>
      </FlexWrapper>
      <FlowPolicyHolderInfoFormDefaultInputs
        errors={errors}
        values={values}
        handleChange={handleChange}
      />
    </form>
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  .Select__option {
    color: #8990a3;
  }

  .Select__option--is-focused {
    color: white;
    background: #8990a3;
  }

  .Select__option--is-selected {
    background: lightgrey;
  }
`;

const NameInput = styled.p`
  height: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    /* width: 48%; */
    margin-left: 1rem;
  }
`;

FlowPolicyHolderInfoFormPrivate.propTypes = {
  defaultValues: object.isRequired,
  sendSubmit: func.isRequired,
};

export default FlowPolicyHolderInfoFormPrivate;
