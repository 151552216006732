import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import InfoPopup from "../components/InfoPopup";
import elvasLogo from "../assets/images/elvas_logo.svg";
// import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import FlowSearchForVehicleForm from "../components/FlowSearchForVehicleForm.jsx";
import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import { patchStorage } from "../helpers/storeService.js";
import { retrieveStorageById } from "../helpers/storeService";
import { LoadingSpinner } from "wg-fe-ui/dist";

import i18n from "../i18n";
import { getNestedObject } from "../helpers/objectService";
import { retrieveCarModelByIdV2 } from "../helpers/apiRouterService";

const FlowSearchForVehicle = () => {
  const { id } = useParams();
  const [carBrandImage, setCarBrandImage] = useState(
    "https://files.wegroup.be/avatars/avatar-louise.svg"
  );
  const [defaultValues, setDefaultValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, []);
  const { renderNextRoute } = useRouting();

  function handleBrandLogo(img) {
    if (!img)
      setCarBrandImage("https://files.wegroup.be/avatars/avatar-louise.svg");
    else setCarBrandImage(img);
  }

  function handleManual() {
    patchStorage(
      {
        brand: undefined,
        model: undefined,
        type: undefined,
        model_year: undefined,
        carImg: undefined,
        invoice_value: undefined,
        kWh: undefined,
        options: undefined
      },
      id
    );
    renderNextRoute();
  }

  const handleSubmit = useCallback(
    async (carInfo, brand, model) => {
      if (!carInfo) return;
      setLoading(true);
      const carData = await loadCarData(carInfo, brand, model);
      patchStorage(carData, id);
      setLoading(false);
      renderNextRoute();
    },
    [id]
  );

  const loadCarData = async (carInfo, brand, model) => {
    const { id, model_year, kw } = carInfo;
    if (brand && model && id && model_year && kw) {
      const [resp, status] = await retrieveCarModelByIdV2(brand, model, id);

      if (status !== 200) {
        return;
      }

      const values = {
        brand: getNestedObject(resp.car, ["brand"], ""),
        model: getNestedObject(resp.car, ["series"], ""),
        type: getNestedObject(resp.car, ["version"], ""),
        model_year: getNestedObject(resp.car, ["model_year"], ""),
        kwh: getNestedObject(resp.car, ["kwh"], ""),
        carImg:
          getNestedObject(resp.car, ["images"], "").length > 0
            ? getNestedObject(resp.car, ["images"], "")[0]
            : "",
        invoice_value: (getNestedObject(resp.car, ["price"], 0) || 0).toFixed(2)
      };

      return values;
    } else {
      return false;
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <ElvasLogo src={elvasLogo} alt="" />
        <RoundImageContainer>
          <RoundImage
            showShadow={
              carBrandImage ===
              "https://files.wegroup.be/avatars/avatar-louise.svg"
            }
            img={carBrandImage}
            alt="car brand logo"
          />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                "Start by choosing the brand for your vehicle to be saponified"
              )
            }}
            style={{ width: "90%" }}
          ></p>

          <StyledInfoPopup
            title={i18n.t(
              "Why are only electric vehicles (EVs) eligible and the plug in vehicles? (PHEV) not?"
            )}
            info={i18n.t(
              "Elvas starts from the principle that an EV is built differently from an ordinary car The car components are assembled differently, for example, there is proven to be less wear and tear on the car engine, brakes, etc A plug in (PHEV) still has an internal combustion engine, so this argument doesn't hold There is also the value of the battery and the underlying technology This makes the car a lot more complex, which creates the need for tailor-made insurance"
            )}
          />
        </TextBalloon>
      </LeftSplit>
      {/* <FlowLeftSideAvatar
        chatText={i18n.t(
          "Start by choosing the brand for your vehicle to be saponified"
        )}
        infoHelper={{
          title: i18n.t(
            "Why are only electric vehicles (EVs) eligible and the plug in vehicles? (PHEV) not?"
          ),
          info: i18n.t(
            "Elvas starts from the principle that an EV is built differently from an ordinary car The car components are assembled differently, for example, there is proven to be less wear and tear on the car engine, brakes, etc A plug in (PHEV) still has an internal combustion engine, so this argument doesn't hold There is also the value of the battery and the underlying technology This makes the car a lot more complex, which creates the need for tailor-made insurance"
          )
        }}
      /> */}
      <RightSplit>
        <FlowSearchForVehicleForm
          handleBrandLogo={handleBrandLogo}
          handleSubmit={handleSubmit}
          handleManual={handleManual}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const ElvasLogo = styled.img`
  width: 16rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  background-color: white;
  color: #188bee;
  float: right;
`;

const TextBalloon = styled.div`
  display: flex;
  font-size: 2rem;
  line-height: 1.25;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  margin-bottom: -4%;
  > span {
    font-weight: 700;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 5rem 4rem;

  :after {
    content: "";
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
  border-radius: ${({ showShadow }) => (showShadow ? "50%" : "0")};
  box-shadow: ${({ showShadow }) =>
    showShadow ? "0 0 20px rgba(0, 0, 0, 0.05)" : null};
`;

const RoundImageContainer = styled.div`
  max-width: 33rem;
  width: 100%;
`;

export default FlowSearchForVehicle;
