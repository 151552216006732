/* eslint-disable no-extra-boolean-cast */
/* stylelint-disable value-keyword-case */
import React, { useEffect } from "react";
import styled from "styled-components";
import Drawer from "react-drag-drawer";
import { string, bool, func } from "prop-types";
import i18n from "../i18n";
import { ActionButton } from "wg-fe-ui";
import useRouting from "../hooks/useRouting";

const InfoPopupRemoteSell = ({
  isLink,
  className,
  inverted,
  showPopup,
  setShowPopup,
}) => {
  const { renderNextRoute } = useRouting();

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        setShowPopup(false);
      }
    });
  }, []);

  var dt = new Date();
  const currenDate =
    dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();

  return showPopup ? (
    <>
      <InfoButton className={className} isLink={isLink}>
        {i18n.t("Checkout")}
      </InfoButton>
      <Drawer open={true} onRequestClose={() => setShowPopup(false)}>
        <InfoContainer>
          <CloseButton onClick={() => setShowPopup(false)}>
            {" "}
            &times;{" "}
          </CloseButton>
          <InfoBlock>
            <h3>{i18n.t("Are you sure?")}</h3>
            <h2>
              {i18n.t(
                "By continuing, you agree to the following terms and conditions"
              )}
            </h2>
            <p>
              {i18n.t(
                "I am contractually bound when purchasing the chosen insurance policies unless terminated within the legal term"
              )}
            </p>
            <li>
              {i18n.t("The cancellation period starts today at ")}
              {currenDate}
            </li>
            <li>{i18n.t("You have 14 days to cancel your policys")}</li>
            <li>{i18n.t("TERMS_AND_CONDITIONS_BULLET_3")}</li>
            <li>{i18n.t("TERMS_AND_CONDITIONS_BULLET_4")}</li>
            <li>{i18n.t("TERMS_AND_CONDITIONS_BULLET_5")}</li>
            <li>{i18n.t("TERMS_AND_CONDITIONS_BULLET_6")}</li>
          </InfoBlock>
          <ButtonContainer>
            <StyledActionButton
              className="cancel"
              onClick={() => setShowPopup(false)}
            >
              {i18n.t("Cancel")}
            </StyledActionButton>
            <StyledActionButton onClick={() => renderNextRoute()}>
              {i18n.t("I agree")}
            </StyledActionButton>
          </ButtonContainer>
        </InfoContainer>
      </Drawer>
    </>
  ) : (
    <InfoButton className={className} isLink={isLink} inverted={inverted}>
      {i18n.t("Checkout")}
    </InfoButton>
  );
};

const StyledActionButton = styled(ActionButton)`
  align-items: center;
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 100%;
  justify-content: center;
  padding: 1.5rem 0.4rem;
  min-width: 14rem;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  &.cancel {
    background-color: #aeaeae;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  margin-bottom: 4vw;
`;

const InfoContainer = styled.div`
  color: white;
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  width: 70rem;
  overflow: auto;
  z-index: 9999;
  @media (max-width: 700px) {
    width: 45rem;
  }
  @media (max-width: 470px) {
    width: 35rem;
  }
`;

const InfoBlock = styled.div`
  font-family: ${({ theme }) => theme.font};
  line-height: 1.5;
  padding: 4rem;

  & h2 {
    color: black;
    font-size: 2.2rem;
    font-weight: 900;
    margin-bottom: 2rem;

    ::first-letter {
      text-transform: capitalize;
    }
  }
  & li {
    color: black;
    margin-left: 2rem;
  }

  & p {
    color: black;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  & h3 {
    color: grey;
    text-transform: uppercase;
  }

  & span {
    font-weight: bold;
  }
`;

const InfoButton = styled(ActionButton)`
  font-size: 1.7rem;
  color: ${({ theme, isLink, inverted }) =>
    isLink ? theme.brand.secondary : inverted ? theme.brand.primary : "white"};
  display: inline-block;
  background-color: ${({ theme, inverted }) =>
    inverted ? "white" : theme.brand.primary};
  width: 2.6rem;
  height: 2.6rem;
  text-align: center;
  font-weight: 900;
  border-radius: 50%;
  margin-left: 1rem;
  transition: background-color 0.1s ease;
  border: none;
  padding: 0.15rem;
  line-height: 1.3;
  vertical-align: middle;

  ${(props) =>
    props.isLink
      ? `
    width: auto;
    height: auto;
    font-weight: bold;
    font-size: 1.4rem;
    background: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    margin-top: -.3rem;
    line-height: auto;
  `
      : null}

  &:hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    cursor: pointer;
    color: white;
    ${(props) =>
      props.isLink
        ? `
    background: none;
  `
        : null}
  }
`;

const CloseButton = styled.p`
  cursor: pointer;
  color: ${({ theme }) => theme.brand.primary};
  font-weight: 900;
  font-size: 20px;
  font-family: Arial Unicode MS;
  position: absolute;
  right: 0;
  padding: 1.5rem;
`;

InfoPopupRemoteSell.defaultProps = {
  info: "",
  title: "",
  buttonText: i18n.t("Checkout"),
  onClick: () => {},
  isLink: false,
  inverted: false,
};

InfoPopupRemoteSell.propTypes = {
  info: string,
  title: string,
  buttonText: string,
  isLink: bool,
  onClick: func,
  className: string,
};

export default InfoPopupRemoteSell;
