import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
// import { format } from "date-fns";
import FlowPolicyHolderInfoForm from "../components/FlowPolicyHolderInfoForm.jsx";
import { retrieveStorageById, patchStorage } from "../helpers/storeService.js";
// import { retrieveCarPrice } from "../helpers/apiRouterService.js";
// import { parseIncDateToObject } from "../helpers/dateService";
import elvasLogo from "../assets/images/elvas_logo.svg";
import { getCarPrice, patchquoteOptionsAndPricing } from "../helpers/smtHelper";

const FlowPolicyHolderInfo = () => {
  const [defaultValues, setDefaultValues] = useState();
  const { renderNextRoute } = useRouting();
  const { id } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id) || {};
    setDefaultValues(data);
  }, []);

  const handleFormValues = async () => {
    const [resp, status] = await getCarPrice(id);

    if (status !== 200) return;

    patchquoteOptionsAndPricing(id, resp);
  };

  function handleSubmit(Info) {
    // sendPolicyDataWithCar(Info);
    patchStorage(Info, id);
    handleFormValues();
    renderNextRoute();
  }

  // async function sendPolicyDataWithCar(data) {
  //   callCarPrice(data);
  // }

  // async function callCarPrice(data) {
  //   const { pricingQuery: query } = defaultValues;

  //   const { vinNumber, licensePlate } = data;

  //   const {
  //     firstName,
  //     lastName,
  //     birth,
  //     driverLicenseType,
  //     issueLicenseDate,
  //     numberOfAccidentsLastFiveYears,
  //     specialEmployment,
  //     kWh,
  //     brand,
  //     model,
  //     usedFor,
  //     invoice_value,
  //     options,
  //     version,
  //     quote
  //   } = defaultValues || {};

  //   const baseQuote = quote;
  //   const currentDateObj = parseIncDateToObject(
  //     format(new Date(), "dd/MM/yyyy")
  //   );

  //   const payload = {
  //     car: {
  //       kwh: kWh,
  //       motor_type: "ELE",
  //       brand,
  //       version: version ? version.substring(0, 34) : null,
  //       model,
  //       used_for: usedFor,
  //       value: parseInt(invoice_value),
  //       options_value: parseInt(options),
  //       registration_first: currentDateObj,
  //       chassis_number: vinNumber,
  //       number_plate: licensePlate,
  //       drivers: [
  //         {
  //           first_name: firstName,
  //           last_name: lastName,
  //           birth: parseIncDateToObject(birth),
  //           issue_license_date: parseIncDateToObject(issueLicenseDate),
  //           primary: true,
  //           number_of_accidents_last_five_years: numberOfAccidentsLastFiveYears,
  //           accident_statement: null,
  //           special_employment: specialEmployment === "yes" ? true : false,
  //           driver_license_type: driverLicenseType.toUpperCase()
  //         }
  //       ]
  //     },
  //     quote_specifications: {
  //       currency: "EUR",
  //       payment_interval: "annual",
  //       save_quote: true
  //     }
  //   };

  //   const [resp, status] = await retrieveCarPrice(payload, query);
  //   baseQuote.id = resp.id;
  //   patchStorage({ quote: baseQuote }, id);
  //   return [resp, status];
  // }

  return (
    <SplitContainer>
      <LeftSplit>
        <ElvasLogo src={elvasLogo} alt="" />
        <RoundImage
          src="https://files.wegroup.be/avatars/avatar-louise.svg"
          alt="Louise"
        />
        <TextBalloon>{i18n.t("What kind of person are you")}</TextBalloon>
      </LeftSplit>
      <RightSplit>
        <FlowPolicyHolderInfoForm
          retrieveSubmit={handleSubmit}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const ElvasLogo = styled.img`
  width: 16rem;
`;

const TextBalloon = styled.div`
  font-size: 2rem;
  line-height: 1.25;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 4rem;
  position: relative;

  :after {
    content: "";
    align-self: center;
    width: 1px;
    height: 90%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.img`
  max-width: 33rem;
  border-radius: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
`;

export default FlowPolicyHolderInfo;
