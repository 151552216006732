import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { TextInput } from "wg-fe-ui";

import TerminationPopUp from "../components/TerminationPopUp";
import CollapsibleCheckbox from "./CollapsibleCheckbox";

import i18n from "../i18n.js";

const FlowOverviewFormTermination = ({ values, handleChange, onTerminationDateChange }) => {
  const [toggle, setToggle] = useState(false);
  const [terminationData, setTerminationData] = useState(false);

  useEffect(() => {
    if (terminationData.terminationDate) {
      onTerminationDateChange(terminationData.terminationDate);
    }
  }, [terminationData]);

  return (
    <Container>
      <CollapsibleCheckbox
        onChange={value => {
          if (values.terminatePolicy !== value.value) {
            setToggle(value.value);
          }
          handleChange(value);
        }}
        id="terminatePolicy"
        value={values.terminatePolicy}
        title={i18n.t("Cancel current insurance")}
        shortDiscription={i18n.t("I currently have car insurance")}
      >
        {values.insuranceCompanyName &&
        values.policyNumber &&
        values.terminationDate ? (
          <>
            <TextInput disabled value={values.insuranceCompanyName}>
              {i18n.t("Insurance provider")}
            </TextInput>
            <TextInput disabled value={values.policyNumber}>
              {i18n.t("Policy number")}
            </TextInput>
            <TextInput disabled value={values.terminationDate}>
              {i18n.t("Expiration date")}
            </TextInput>
            <Edit onClick={() => setToggle(true)}>{i18n.t("Edit")}</Edit>
          </>
        ) : null}
      </CollapsibleCheckbox>
      {toggle ? (
        <TerminationPopUp
          parentValues={values}
          onChange={handleChange}
          setTerminationData={setTerminationData}
          data-test-id={`underwriting_termination_popup`}
          toggle={toggle}
          toggleDrawer={() => setToggle(!toggle)}
        />
      ) : null}
    </Container>
  );
};

const Edit = styled.p`
  cursor: pointer;
  color: ${({ theme }) => theme.brand.primary};
`;

const Container = styled.div`
  margin-top: 2rem;
`;

export default FlowOverviewFormTermination;
