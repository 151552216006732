import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useForm from "../hooks/useForm.js";
import {
  TextInput,
  SearchSelectInput,
  ActionButton,
  DateInputV4 as DateInput,
} from "wg-fe-ui";
import * as Yup from "yup";
import { string, year, number, price } from "../constants/validationSchemas.js";
import { fuelType } from "../constants/FlowSearchSelectData.js";
import OptionsPriceInput from "./VATInputSwitcherV2";
import i18n from "../i18n";
import InfoPopup from "./InfoPopup";
import { patchStorage } from "../helpers/storeService.js";
import { useParams } from "react-router";

const FlowManualVehicleForm = ({
  defaultValues,
  resetImage,
  renderNextRoute,
}) => {
  const { id } = useParams();
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const validationSchema = Yup.object().shape({
    brand: string.required,
    model: string.required,
    version: string.notRequired,
    model_year: year.notRequired,
    kWh: number.required,
    invoice_value: price.required,
    registration_first: string.required,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    Object.keys(defaultValues).map((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }, [defaultValues]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const handleFormValues = () => {
    Object.keys(values).map((key) => {
      patchStorage({ [key]: values[key] }, id);
    });
    renderNextRoute();
  };

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setFuelTypeOptions(
      fuelType.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <FlexWrapper>
        <StyledTextInput
          name="brand"
          error={errors.brand}
          onChange={(val) => {
            handleChange(val);
            resetImage();
          }}
          value={values.brand}
        >
          {i18n.t("Brand")} *
        </StyledTextInput>
        <StyledTextInput
          name="model"
          error={errors.model}
          onChange={(val) => {
            handleChange(val);
            resetImage();
          }}
          value={values.model}
        >
          {i18n.t("Model")} *
        </StyledTextInput>
      </FlexWrapper>
      <StyledTextInput
        name="version"
        error={errors.version}
        onChange={(val) => {
          handleChange(val);
        }}
        value={values.version}
      >
        {i18n.t("Version")}
      </StyledTextInput>
      <SearchSelectInput
        onChange={(val) => {
          handleSelectChange(val, "motorType");
        }}
        name="motorType"
        error={errors.motorType}
        disabled
        options={fuelTypeOptions}
        initial={fuelTypeOptions[0]}
      >
        {i18n.t("Fuel type")}
      </SearchSelectInput>
      <FlexWrapper>
        <StyledTextInput
          name="model_year"
          error={errors.model_year}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.model_year}
        >
          {i18n.t("Model year")}
        </StyledTextInput>
        <StyledHelperInput
          name="kWh"
          error={errors.kWh}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.kWh}
        >
          <div>
            {i18n.t("kWh")} *
            <StyledInfoPopup
              title={i18n.t("kWh")}
              info={i18n.t(
                "In the case of an electric car, consumption is expressed in the number of kWh needed to drive 100 kilometers"
              )}
            />
          </div>
        </StyledHelperInput>
      </FlexWrapper>
      <OptionsPriceInput
        name="invoice_value"
        id="invoice_value"
        error={errors.invoice_value}
        onChange={({ value, isVATInclusive }) =>
          handleChange({
            name: "invoice_value",
            value: isVATInclusive ? (value / 1.21).toFixed(2) : value,
          })
        }
        value={values.invoice_value}
        defaultValue={defaultValues.invoice_value}
        popUpTitle={i18n.t("Invoice value")}
        popUpInfo={i18n.t(
          "The invoice value of the car is the new price This price does not include discounts, trade-in conditions and show-room conditions The entry of the value of your car can be done excluding or including VAT, by clicking on the `Excl/Incl VAT` button"
        )}
      >
        {i18n.t("Invoice value")}
      </OptionsPriceInput>
      <p>
        {i18n.t("Carefully check the details on your invoice / order form")}
      </p>
      <OptionsPriceInput
        name="options"
        id="options"
        error={errors.options}
        onChange={({ value, isVATInclusive }) =>
          handleChange({
            name: "options",
            value: isVATInclusive ? (value / 1.21).toFixed(2) : value,
          })
        }
        value={values.options}
        defaultValue={defaultValues.options}
        popUpTitle={i18n.t("Options value")}
        popUpInfo={i18n.t("Options value info")}
      >
        <Options>{i18n.t("Value of additional options")}</Options>
      </OptionsPriceInput>
      <StyledDateInput
        error={errors.registration_first}
        name="registration_first"
        onChange={({ value }) =>
          handleChange({
            name: "registration_first",
            value: value,
          })
        }
        value={values.registration_first}
      >
        {i18n.t("First registration")}
      </StyledDateInput>
      <ButtonContainer>
        <ActionButton type="submit" data-test-id="manual_vehicle_flow_submit">
          {i18n.t("Confirm")}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};

const StyledDateInput = styled(DateInput)`
  > div {
    margin-top: 1.6rem;
  }
`;

const Options = styled.span`
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: #5b5550;
  line-height: 1.1rem;
  display: flex;
  height: 1rem;
  margin: 0;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  p {
    color: grey;
    font-size: 1.3rem;
    margin: 0 0 2rem 0.5rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
`;

const StyledHelperInput = styled(StyledTextInput)`
  input {
    margin-top: 0.8rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: 1.6rem;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default FlowManualVehicleForm;
