import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router";

import FlowOverviewForm from "../components/FlowOverviewForm";
import FlowOverviewDriversInformation from "../components/FlowOverviewDriversInformation";

import { retrieveStorageById, patchStorage } from "../helpers/storeService.js";
import { getNestedObject } from "../helpers/objectService";
import i18n from "../i18n";
import InfoPopupRemoteSell from "../components/InfoPopupRemoteSell.jsx";
import {
  date,
} from "../constants/validationSchemas";
import useForm from "../hooks/useForm";
import * as Yup from "yup";
import { isFuture } from "date-fns";

export function checkIfFilesAreTooBig(files) {
  let valid = false
  if (files) {
    Object.values(files).map(file => {
      const size = file.size / 1024 / 1024
      if (size <= 3) {
        valid = true
      }
    })
  }
  return valid
}

export function checkIfFilesAreCorrectType(files, types) {
  let valid = false
  if (files) {
    Object.values(files).map(file => {
      let fileType = file.name
        .split(".")[file.name.split(".").length - 1].toLowerCase();
      if (types.includes(fileType)) {
        valid = true
      }
    })
  }
  return valid
}

const FlowOverview = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { id } = useParams();
  const { data } = retrieveStorageById(id) || {};
  const [defaultValues, setDefaultValues] = useState({});
  const [filledReceipt, setFilledReceipt] = useState(!!getNestedObject(data, ['attachments', 'receipt']) || false);
  const [filledRegistrationForm, setFilledRegistrationForm] = useState(!!getNestedObject(data, ['attachments', 'registrationForm']) || false);

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, []);

  useEffect(() => {
    handleDefaultValues();
  }, [defaultValues]);

  const hasOmnium = data.pricingQuery.includes("option=omnium");
  const schemaObject = {
    startDate: date.test(
      "valid-birth",
      i18n.t("Date should be in the future"),
      value => value ? isFuture(new Date(value.split('/')[2], value.split('/')[1] - 1,value.split('/')[0])) : false,
    ),
    receipt: Yup.mixed()
      .nullable()
      .test('preFilled', i18n.t('required'), value => filledReceipt || value)
      .test(
        'is-big-file',
        i18n.t("File type must be pdf, docx, doc, jpg, png, heic, heif"),
        value => filledReceipt || checkIfFilesAreCorrectType(value, ["pdf", "docx", "doc", "jpg", "png", "heic", "heif"])
      )
      .test('is-correct-file', i18n.t("File size must not exceed 3MB"), value => filledReceipt || checkIfFilesAreTooBig(value)),
    registrationForm: Yup.mixed()
      .nullable()
      .test('preFilled', i18n.t('required'), value => filledRegistrationForm || value)
      .test(
        'is-big-file',
        i18n.t("File type must be pdf, docx, doc, jpg, png, heic, heif"),
        value => filledRegistrationForm || checkIfFilesAreCorrectType(value, ["pdf", "docx", "doc", "jpg", "png", "heic", "heif"])
      )
      .test('is-correct-file', i18n.t("File size must not exceed 3MB"), value => filledRegistrationForm || checkIfFilesAreTooBig(value)),
    checklist: Yup.bool().required('Field must be checked').oneOf([true], 'Field must be checked'),
  }
  if (!hasOmnium) delete schemaObject.receipt;
  const validationSchema = Yup.object().shape(schemaObject);


  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema
  });


  function handleDefaultValues() {
    Object.keys(defaultValues).map(name => {
      if (name !== 'checklist') {
        handleChange({ name, value: defaultValues[name] });
      }
    });
  }

  async function sendSubmit(values) {
    var reader = {
      receipt: new FileReader(),
      registrationForm: new FileReader(),
    };
    const attachments = {};
    Object.keys(values).map(key => {
      if (typeof values[key] !== 'object') {
        patchStorage({[key]: values[key]}, id);
      } else {
        if ((key === 'receipt' && !filledReceipt) || (key === 'registrationForm' && !filledRegistrationForm)) {
          const f = values[key][0];
          reader[key].onload = (function() {
            return function(e) {
              var binaryDataSignupForm = e.target.result;
              attachments[key] = {
                type: f.name
                  .split(".")[f.name.split(".").length - 1].toLowerCase(),
                base64: window.btoa(binaryDataSignupForm),
                filename: f.name.replace(/ /g, "_").replace(/'/g, "").toLowerCase()
              }
              patchStorage({attachments}, id);
            };
          })(f);
          reader[key].readAsBinaryString(f);
        }
      }
    });
    setShowPopup(true);
  }
  const handleForm = e => {
    e.preventDefault();
    handleSubmit(sendSubmit);
  };

  var myObj = {
    style: "currency",
    currency: "EUR"
  };

  return (
    <SplitContainer onSubmit={handleForm}>
      <LeftSplit>
        <FlowOverviewDriversInformation
          errors={errors}
          values={values}
          handleChange={handleChange}
          setFilledReceipt={setFilledReceipt}
          filledReceipt={filledReceipt}
          filledRegistrationForm={filledRegistrationForm}
          setFilledRegistrationForm={setFilledRegistrationForm}
          storageData={data}
        />
      </LeftSplit>
      <RightSplit>
        <FlowOverviewForm
          errors={errors}
          values={values}
          handleChange={handleChange}
          storageData={data}
          quote={getNestedObject(data, ["quote"])}
        />
        <Checkout>
          <h3>
            {i18n.t("Total premium")}:
            <br />
            {parseFloat(
              getNestedObject(data, ["premium", "totalPremium"], 0)
            ).toLocaleString("nl-BE", myObj)}{" "}
            / {i18n.t("Year")}
          </h3>
          <StyledInfoPopupRemoteSell
            setShowPopup={setShowPopup}
            showPopup={showPopup}
          />
        </Checkout>
      </RightSplit>
    </SplitContainer>
  );
};

const StyledInfoPopupRemoteSell = styled(InfoPopupRemoteSell)`
  align-items: center;
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 6rem;
  justify-content: center;
  padding: 1rem 0.4rem;
  margin-left: auto;
  margin-top: auto;
  min-width: 14rem;
  width: 20rem;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  @media (max-width: 940px) {
    width: 100%;
  }
`;

const Checkout = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin: 5rem 1vw 2rem 1vw;
  h3 {
    font-size: 2.5rem;
    align-self: center;
  }
  @media (max-width: 940px) {
    flex-direction: row;
    grid-template-columns: auto;
    text-align: center;
    h3 {
      margin: 2rem;
    }
  }
  @media (min-width: 1010px) {
    h3 {
      margin-left: 2rem;
    }
  }
`;

const LeftSplit = styled.div`
  width: 50%;
  padding: 3rem;
  justify-content: center;
  display: flex;
  :after {
    @media screen and (min-width: 769px) {
      content: "";
      width: 1px;
      height: 72.4%;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 50%;
    }
  }

  @media (max-width: 768px) {
    padding: 3rem 2rem 0rem 2rem;
    width: 100%;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 3rem 2rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.form`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export default FlowOverview;
