/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { func, string, bool, node } from "prop-types";

const DateInputComponent = ({
  error,
  name,
  includeYear,
  value,
  onChange,
  onBlur,
  children,
  className,
  otherProps
}) => {
  const [day, setDay] = useState(value.split("/")[0]); // Initialize the data with the data supplied to formik
  const [month, setMonth] = useState(
    !isNaN(parseInt(value.split("/")[1])) ? value.split("/")[1] : ``
  );
  const [year, setYear] = useState(
    !isNaN(parseInt(value.split("/")[2])) ? value.split("/")[2] : ``
  );

  const ARROW_LEFT = 37;
  const ARROW_TOP = 38;
  const ARROW_RIGHT = 39;
  const ARROW_BOTTOM = 40;

  // Change focus to the field with the classname in the nextField argument
  const focusNextField = nextField => {
    const nextInput = document.querySelector(`.${name}_${nextField}`);
    nextInput.focus();
    nextInput.setSelectionRange(0, nextInput.getAttribute("maxlengthvalue"));
  };

  // One function to manage all extra keydown functionality in the inputs
  const keyDownHandler = (
    event,
    input,
    setValue,
    max,
    min,
    type,
    leftField,
    rightField
  ) => {
    if (type !== "day") {
      // If the inputType isn't day, go to previous field on left arrow key
      if (event.keyCode === ARROW_LEFT) {
        if (event.target.selectionStart === 0) {
          event.preventDefault();
          focusNextField(leftField);
        }
      }

      // If you use backspace on empty input, go to previous input
      if (event.keyCode === 8) {
        if (event.target.value === "") {
          event.preventDefault();
          focusNextField(leftField);
        }
      }
    } else {
      // On left arrow key select everything and do nothing else
      if (event.keyCode === ARROW_LEFT) {
        event.preventDefault();
        input.setSelectionRange(
          0,
          parseInt(input.getAttribute("maxlengthvalue"))
        );
      }
    }
    if (type !== "year") {
      // If type isn't year, go to next field on right arrow key
      if (event.keyCode === ARROW_RIGHT) {
        if (
          event.target.selectionEnd ===
          parseInt(input.getAttribute("maxlengthvalue"))
        ) {
          event.preventDefault();
          focusNextField(rightField);
        }
      }
    } else {
      // Else if it is year, don't do anything except for select everything
      if (event.keyCode === ARROW_RIGHT) {
        if (
          event.target.selectionEnd ===
          parseInt(event.target.getAttribute("maxlengthvalue"))
        ) {
          event.preventDefault();
          input.setSelectionRange(0, input.getAttribute("maxlengthvalue"));
        }
      }
    }
    if (event.keyCode === ARROW_TOP) {
      // Increment the value of the focused input by 1
      event.preventDefault();
      if (parseInt(event.target.value) < max) {
        setValue(pad(parseInt(event.target.value) + 1).toString());
        input.setSelectionRange(0, input.getAttribute("maxlengthvalue"));
      }
    }
    if (event.keyCode === ARROW_BOTTOM) {
      // Decrement the value of the focused input by 1
      event.preventDefault();
      if (parseInt(event.target.value) > min) {
        setValue(pad(parseInt(event.target.value) - 1).toString());
        input.setSelectionRange(0, input.getAttribute("maxlengthvalue"));
      }
    }
  };

  useEffect(() => {
    // Create all the event listeners
    const dayInput = document.querySelector(`.${name}_day`);
    const monthInput = document.querySelector(`.${name}_month`);
    if (includeYear) {
      const yearInput = document.querySelector(`.${name}_year`);
      yearInput.addEventListener("keydown", e => {
        keyDownHandler(e, yearInput, setYear, 9999, 0, "year", "month", null);
      });
    }
    dayInput.addEventListener("keydown", e => {
      if (!includeYear) {
        keyDownHandler(e, dayInput, setDay, 31, 1, "day", null, "month");
      } else {
        keyDownHandler(e, dayInput, setDay, 31, 1, "day", null, "month");
      }
    });
    monthInput.addEventListener("keydown", e => {
      if (!includeYear) {
        keyDownHandler(e, monthInput, setMonth, 12, 1, "month", "day", "day");
      } else {
        keyDownHandler(e, monthInput, setMonth, 12, 1, "month", "day", "year");
      }
    });
  }, []);

  useEffect(() => {
    setDay(value.split("/")[0]); // Initialize the data with the data supplied to formik
    setMonth(!isNaN(parseInt(value.split("/")[1])) ? value.split("/")[1] : ``);
    if (includeYear) {
      setYear(!isNaN(parseInt(value.split("/")[2])) ? value.split("/")[2] : ``);
    }
  }, [value]);

  // Pad the value -> pad(4) returns '04', pad(11) returns '11'
  const pad = n => (n < 10 ? "0" + n : n);

  // Single functions to handle all blurs
  const blurHandlerType = (elem, max, min, setValue, oldValue) => {
    let tempInput;
    // If the blurred element only has one decimal, pad it
    if (elem.value.length === 1) {
      tempInput = pad(elem.value);
    } else {
      tempInput = elem.value;
    }
    if (
      (parseInt(tempInput) < max && parseInt(tempInput) >= min) ||
      tempInput === ""
    ) {
      // If the input only has '0', empty it
      if (parseInt(tempInput) === 0) {
        tempInput = "";
      }
      setValue(tempInput.toString());
    } else {
      // If the input is bigger than the max or smaller than the min, ignore the second digit and pad the first
      setValue(pad(parseInt(oldValue)).toString());
    }
  };

  const handleBlurInput = e => {
    switch (e.target.getAttribute("data-inputtype")) {
      case "day":
        blurHandlerType(e.target, 32, 0, setDay, day);
        break;
      case "month":
        blurHandlerType(e.target, 13, 0, setMonth, month);
        break;
      case "year":
        setYear(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleChangedInputForType = (
    e,
    nextField,
    max,
    min,
    setValue,
    oldValue,
    type
  ) => {
    if (type !== "year") {
      let tempValue;
      if (
        e.target.value.length === 1 &&
        parseInt(e.target.value) > Math.floor(max / 10)
      ) {
        // if the first digit is high so that every other second digit would make the number too big
        // pad the first digit and focus to the next field
        tempValue = pad(e.target.value);
        if (!(!includeYear && type === "month")) {
          focusNextField(nextField);
        }
      } else {
        // Else, just add it, if the input is filled, go to next input
        tempValue = e.target.value;
        if (e.target.value.length === 2) {
          if (!(!includeYear && type === "month")) {
            focusNextField(nextField);
          }
        }
      }
      if (e.target.value.length > 2) {
        // If the input has more than 2 digits, take the old value
        tempValue = oldValue;
      }
      if (!isNaN(tempValue)) {
        if (
          (parseInt(tempValue) < max && parseInt(tempValue) >= min) ||
          tempValue === ""
        ) {
          // If the digit is a number and is valid, set it
          setValue(tempValue.toString());
        } else {
          // if not correct, reset to old value
          setValue(pad(parseInt(oldValue)).toString());
        }
      }
    } else {
      let tempValue = e.target.value;
      if (e.target.value.length > 4) {
        tempValue = oldValue;
      }
      if (!isNaN(tempValue)) {
        setValue(tempValue.toString());
      }
      setValue(tempValue.toString());
    }
  };

  const handleChangedInput = e => {
    switch (e.target.getAttribute("data-inputtype")) {
      case "day":
        handleChangedInputForType(e, "month", 32, 0, setDay, day, "day");
        break;
      case "month":
        const next = includeYear ? "year" : null;
        handleChangedInputForType(e, next, 13, 0, setMonth, month, "month");
        break;
      case "year":
        handleChangedInputForType(e, null, 9999, 0, setYear, year, "year");
        break;
    }
  };

  useEffect(() => {
    if (includeYear) {
      // If any of the hooks are updated, update the formik value for validation
      if (day !== "" || year !== "" || month !== "") {
        onChange({ name, value: `${day}/${month}/${year}` });
      }
    } else {
      // If any of the hooks are updated, update the formik value for validation
      if (day !== "" || month !== "") {
        onChange({ name, value: `${day}/${month}` });
      }
    }
  }, [year, day, month]);

  return (
    <Container className={className}>
      <Label>{children}</Label>
      <Input {...otherProps} error={error} htmlFor="day" onBlur={onBlur}>
        <StyledSingleInputDate
          data-inputtype="day"
          className={`${name}_day`}
          value={day}
          maxLength={2}
          maxValue={31}
          onBlur={e => handleBlurInput(e)}
          onChange={handleChangedInput}
          placeholder="DD"
        />
        {"/"}
        <StyledSingleInputDate
          data-inputtype="month"
          className={`${name}_month`}
          value={month}
          maxLength={2}
          maxValue={12}
          onBlur={e => handleBlurInput(e)}
          onChange={handleChangedInput}
          placeholder="MM"
        />
        {includeYear ? (
          <>
            /
            <StyledSingleInputDate
              data-inputtype="year"
              className={`${name}_year`}
              value={year}
              maxValue={9999}
              maxLength={4}
              onBlur={e => handleBlurInput(e)}
              onChange={handleChangedInput}
              placeholder="YYYY"
            />
          </>
        ) : null}
      </Input>
      {error ? (
        <ErrorContainer className="errorBox">
          <ErrorMss name={name} component="p" className="error">
            {error}
          </ErrorMss>
        </ErrorContainer>
      ) : null}
    </Container>
  );
};

const Label = styled.label`
  font-size: 1.4rem;
  color: #5b5550;
  line-height: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSingleInputDate = styled.input`
  flex-grow: 0;
  flex-shrink: 0;
  width: ${props => (props["data-inputtype"] === "year" ? "5rem" : "2.9rem")};
  border: none;
  letter-spacing: 0.1rem;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  display: block;
  text-align: center;
  font-size: 1.6rem;
  margin: 0 0.1rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    letter-spacing: 0;
  }
`;

const ErrorContainer = styled.div`
  height: 1.5rem;
  margin-top: 0.8rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const ErrorMss = styled.p`
  color: red;
  font-size: 1.1rem;
  width: 100%;
  margin-top: -1rem;
  margin-left: 0.5rem;
  text-align: right;
`;

const Input = styled.label`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  margin-top: 1.4rem;
  overflow: hidden;
  justify-content: flex-start;
  background-color: white;
  padding: 0.9rem 0.6rem;
  height: 4rem;
  border: ${props =>
    props.error ? "0.1rem solid red" : "0.1rem solid #d3d4d8"};
  border-radius: 0.3rem;
`;

DateInputComponent.defaultProps = {
  value: "",
  error: "",
  name: "",
  includeYear: true,
  onChange: () => {},
  onBlur: () => {}
};

DateInputComponent.propTypes = {
  error: string.isRequired,
  value: string.isRequired,
  name: string.isRequired,
  includeYear: bool,
  onChange: func.isRequired,
  onBlur: func,
  children: node
};

export default DateInputComponent;
