import React from "react";
import styled from "styled-components";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import i18n from "../i18n";

const FlowAcceptanceCondition = () => {
  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          "It is not possible to obtain an price for the provided information For further steps please contact Group Casier"
        )}
        width="50%"
      />
      <RightSplit>
        <Ul>
          <H1>Casier Risk & Insurance cvba</H1>
          <Li>Waalvest, 2 /0001</Li>
          <Li>B-8930 Menen</Li>
          <Li>Telefoon: 056 511 201</Li>
          <Li>elvas@casier.be</Li>
          <Li>FSMA 0405.468.215</Li>
          <Li>RPR Gent, afdeling Kortrijk</Li>
        </Ul>
        <IFRAME
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2522.0611840256315!2d3.1210562159236503!3d50.79297467050562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32e0f522763e5%3A0xe00977d4ec3d069a!2sCasier%20Risk%20%26%20Insurance!5e0!3m2!1sen!2sbe!4v1579951776979!5m2!1sen!2sbe"
          frameborder="0"
          allowfullscreen=""
        ></IFRAME>

        <Ul>
          <H1>Group Casier Risk & Insurance cvba</H1>
          <Li>E3-plein 3</Li>
          <Li>B-9050 Ledeberg (Gent)</Li>
          <Li>Telefoon: 09 231 25 25</Li>
          <Li>elvas@groupcasier.eu</Li>
          <Li>FSMA 0417.102.770</Li>
          <Li>RPR Gent, afdeling Gent</Li>
        </Ul>
        <IFRAME
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.936168576035!2d3.73479221593019!3d51.035799652894916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c373fff8dfe8e5%3A0x356dda040d587517!2sGroup%20Casier%20Risk%20%26%20Insurance%20cvba!5e0!3m2!1sen!2sbe!4v1579951732732!5m2!1sen!2sbe"
          frameborder="0"
          allowfullscreen=""
          className="last"
        ></IFRAME>
      </RightSplit>
    </SplitContainer>
  );
};

const Li = styled.li`
  font-size: 1.8rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.typo.subTitle};
  @media (max-width: 1100px) {
    font-size: 1.6rem;
  }
  @media (max-width: 960px) {
    font-size: 1.5rem;
  }
`;

const Ul = styled.ul`
  margin: 0.5rem 0.5rem 2rem 0;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    margin-top: 0rem;
  }
`;

const H1 = styled.h1`
  font-size: 2.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.brand.primary};
  width: 80%;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1100px) {
    font-size: 2.2rem;
  }
  @media (max-width: 960px) {
    font-size: 2rem;
  }
`;

const IFRAME = styled.iframe`
  width: 100%;
  height: 50%;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    &.last {
      padding-bottom: 5rem;
    }
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem;
  overflow-y: auto;
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 50% 50%;

  @media (max-width: 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowAcceptanceCondition;
