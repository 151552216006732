import React from "react";
import styled from "styled-components";

import InfoPopup from "./InfoPopup";

const LouiseTextBubble = ({ chatText, infoHelper }) => {
  return (
    <TextBalloonWrapper>
      <TextBalloon>
        <p className="text" dangerouslySetInnerHTML={{ __html: chatText }}></p>
        {infoHelper ? (
          <StyledInfoPopup
            inverted
            title={infoHelper.title}
            info={infoHelper.info}
          />
        ) : null}
      </TextBalloon>
    </TextBalloonWrapper>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
`;

const TextBalloon = styled.p`
  display: flex;
  font-size: 2rem;
  line-height: 1.25;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  > span {
    font-weight: 700;
  }
  @media (max-width: 1140px) {
    margin: 1.5rem 0rem;
    .text {
      margin-left: auto;
      width: 90%;
    }
  }
`;

const TextBalloonWrapper = styled.div`
  display: none;
  margin: 0 auto;
  flex-direction: column;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default LouiseTextBubble;
