import React, { useReducer, useEffect } from "react";
import FlowPriceCalculatorOption from "./FlowPriceCalculatorOption";
import pricingOptions from "../constants/PricingOptions";
import { getNestedObject } from "../helpers/objectService";
import i18n from "../i18n";

const FlowPriceCalculatorOptions = ({ subPrices, onChange }) => {
  function handleOptionChange(name, status) {
    if (status) {
      dispatch({ type: "add", value: name });
    } else {
      dispatch({ type: "remove", value: name });
    }
  }

  const [selectedOptions, dispatch] = useReducer((arr, { type, value }) => {
    switch (type) {
      case "add":
        return [...arr, value];
      case "remove":
        return arr.filter((item) => item !== value);
      default:
        return arr;
    }
  }, []);

  useEffect(() => {
    processOptionsqueryString(
      selectedOptions.filter((item) => item !== "civil_liability")
    );
  }, [selectedOptions]);

  function handleSubPrices(name) {
    if (name === "civil_liability") {
      return getNestedObject(subPrices, ["quote", "base", "total_premium"]);
    }

    const options = getNestedObject(subPrices, ["quote", "options"]) || [];
    const currentOption = options.filter((item) => item.name === name)[0] || {};
    if (name === "omnium" && currentOption.total_premium === null) {
      return null;
    }
    return currentOption.total_premium || 0;
  }

  function processOptionsqueryString(selectedOptions) {
    if (selectedOptions === undefined || selectedOptions.length == 0) return;
    const queryString = "option=" + selectedOptions.join("&option=");

    onChange(queryString);
  }

  return (
    <>
      {pricingOptions.map(
        ({ id, title, ipidLink, termsLink, mandatory, helper }) => (
          <FlowPriceCalculatorOption
            id={id}
            key={id}
            title={i18n.t(title)}
            ipidLink={ipidLink}
            termsLink={termsLink}
            onChange={handleOptionChange}
            mandatory={mandatory}
            price={handleSubPrices(id)}
            disabled={!handleSubPrices(id)}
            helperText={i18n.t(helper)}
          />
        )
      )}
    </>
  );
};
export default FlowPriceCalculatorOptions;
