import React from "react";
import styled from "styled-components";

import InfoPopup from "./InfoPopup";

import elvasLogo from "../assets/images/elvas_logo.svg";
const FlowLeftSideAvatar = ({ chatText, infoHelper, width = "50%" }) => {
  return (
    <LeftSplit width={width}>
      <ElvasLogo src={elvasLogo} alt="" />
      <RoundImage
        src="https://files.wegroup.be/avatars/avatar-louise.svg"
        alt="louise"
      />
      <TextBalloonWrapper>
        <TextBalloon>
          <p
            className="text"
            dangerouslySetInnerHTML={{ __html: chatText }}
          ></p>
          {infoHelper ? (
            <StyledInfoPopup
              inverted
              title={infoHelper.title}
              info={infoHelper.info}
            />
          ) : null}
        </TextBalloon>
      </TextBalloonWrapper>
    </LeftSplit>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  background-color: white;
  color: #188bee;
  float: right;
`;

const ElvasLogo = styled.img`
  width: 16rem;
`;

const TextBalloon = styled.div`
  display: flex;
  font-size: 2rem;
  line-height: 1.25;
  min-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  > span {
    font-weight: 700;
  }
  .text {
    width: 90%;
  }
`;

const TextBalloonWrapper = styled.div`
  /* height: 10%; */
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeftSplit = styled.div`
  width: ${({ width }) => width};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 4rem;

  :after {
    content: "";
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: ${({ width }) => width};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.img`
  max-width: 33rem;
  border-radius: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
`;

export default FlowLeftSideAvatar;
