import React, { useEffect, Suspense, useState } from "react";
import styled from "styled-components";
import { ActionButton, SearchSelectInput, Label } from "wg-fe-ui";
import DateInput from "../components/DateInput";
import * as Yup from "yup";
import i18n from "../i18n";

import { differenceInYears } from "date-fns";

import {
  driverLicenseTypeOptions,
  numberOfAccidentsLastFiveYearsOptions,
  primaryOptions as primaryOptionsConstant,
  usedForOptions as usedForOptionsConstant,
} from "../constants/FlowSearchSelectData";

import { string, date } from "../constants/validationSchemas.js";
import {
  parseObjectToNewDate,
  parseIncDateToObject,
  parseIncDate,
} from "../helpers/dateService.js";
import useForm from "../hooks/useForm";

const FlowDriversInformationForm = ({ handleFormValues, defaultValues }) => {
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [numberOfAccidentsOptions, setNumberOfAccidentsOptions] = useState([]);
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [usedForOptions, setUsedForOptions] = useState([]);

  const SignupSchema = Yup.object().shape({
    birth: date.test(
      "valid-birth",
      i18n.t("The driver must be between 17 and 100 years of age"),
      handleBirthValidation
    ),
    driverLicenseType: string.required,
    issueLicenseDate: date.test(
      "issueLicenseDate",
      i18n.t("The driver is too young to obtain a driving licence"),
      handleDriveLicenseAge
    ),
    numberOfAccidentsLastFiveYears: string.required,
    primary: string.required,
    usedFor: string.required,
  });

  const SelectPlaceholder = i18n.t("Choose your option");

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    setDefaultValues();
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setLicenseOptions(
      driverLicenseTypeOptions.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setNumberOfAccidentsOptions(
      numberOfAccidentsLastFiveYearsOptions.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setPrimaryOptions(
      primaryOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setUsedForOptions(
      usedForOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function setDefaultValues() {
    Object.keys(defaultValues).map((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  function handleDriveLicenseAge() {
    const { birth, issueLicenseDate } = this.parent;

    // Minimum is 2 jaar: in acceptatie is dit geen probleem in de huidige flow.
    // 20211220 Lower the driving age to 18 years
    let minimumDrivingAge = 18;

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseIncDate(dateObject);

    const licenseObject = parseIncDateToObject(issueLicenseDate);
    const licenseDate = parseIncDate(licenseObject);

    return differenceInYears(licenseDate, birthDate) >= minimumDrivingAge;
  }

  function handleBirthValidation() {
    const { birth } = this.parent;

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseObjectToNewDate(dateObject);

    return (
      17 <= differenceInYears(new Date(), birthDate) &&
      differenceInYears(new Date(), birthDate) <= 100
    );
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleFormValues);
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <div>
          <StyledLabel>
            {i18n.t("What is the date of birth of the main driver")}
          </StyledLabel>
          <StyledDateInput
            name="birth"
            error={errors.birth}
            onChange={handleChange}
            value={defaultValues.birth}
          />
        </div>
        <div>
          <StyledLabel>
            {i18n.t("What kind of driving licence does the main driver hold")}
          </StyledLabel>
          <SelectInput
            error={errors.driverLicenseType}
            name="driverLicenseType"
            onChange={(val) => {
              handleSelectChange(val, "driverLicenseType");
            }}
            options={licenseOptions}
            placeholder={SelectPlaceholder}
            isClearable
            value={handleSelectValue(licenseOptions, "driverLicenseType")}
          />
        </div>
        <div>
          <StyledLabel>
            {i18n.t("When did the main driver obtain the driving licence")}
          </StyledLabel>
          <StyledDateInput
            onChange={handleChange}
            error={errors.issueLicenseDate}
            name="issueLicenseDate"
            value={defaultValues.issueLicenseDate}
          />
        </div>
        <div>
          <StyledLabel>
            {i18n.t(
              "How many accidents has the main driver had in the last five years"
            )}
          </StyledLabel>
          <SelectInput
            error={errors.numberOfAccidentsLastFiveYears}
            name="numberOfAccidentsLastFiveYears"
            onChange={(val) => {
              handleSelectChange(val, "numberOfAccidentsLastFiveYears");
            }}
            options={numberOfAccidentsOptions}
            placeholder={SelectPlaceholder}
            isClearable
            value={handleSelectValue(
              numberOfAccidentsOptions,
              "numberOfAccidentsLastFiveYears"
            )}
          />
        </div>
        <div>
          <StyledLabel className="padding">
            {i18n.t("Is the main driver also the policyholder")}
          </StyledLabel>
          <SelectInput
            error={errors.primary}
            name="primary"
            onChange={(val) => {
              handleSelectChange(val, "primary");
            }}
            options={primaryOptions}
            placeholder={SelectPlaceholder}
            isClearable
            value={handleSelectValue(primaryOptions, "primary")}
          />
        </div>
        <div>
          <StyledLabel className="padding">
            {i18n.t("For what purpose is the car used")}
          </StyledLabel>
          <SelectInput
            error={errors.usedFor}
            name="usedFor"
            onChange={(val) => {
              handleSelectChange(val, "usedFor");
            }}
            options={usedForOptions}
            placeholder={SelectPlaceholder}
            isClearable
            value={handleSelectValue(usedForOptions, "usedFor")}
          />
        </div>
        <ButtonContainer>
          <ActionButton
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t("Next")}
          </ActionButton>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const StyledDateInput = styled(DateInput)`
  margin-bottom: 3rem;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default FlowDriversInformationForm;
