import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import FlowAddressInput from "./FlowAddressInput";
import { object, func } from "prop-types";
import { fetchStreets, fetchCities } from "../helpers/addressService";
import { SearchSelectInput, TextInput } from "wg-fe-ui/dist";

const FlowAddressContainer = ({ errors, values, handleChange }) => {
  const [addressValues, setAddressValues] = useState(values);
  const [streetNames, setStreetNames] = useState();
  const [municipalityName, setMunicipalityName] = useState();
  const [selected, setSelected] = useState({});
  const [postals, setPostals] = useState({});

  useEffect(() => {
    //called once when page loads
    //not called again until given in address AND number, and hitting enter => called when all values are filled in
    //called twice in total: when page loads, and when the address is given in and the API call is done to fill in the remaining inputs

    if (addressValues) {
      const selectedObj = {};
      Object.keys(addressValues).forEach((key) => {
        handleChange({ name: key, value: addressValues[key] });
        const selectedItem = {
          label: addressValues[key],
          value: addressValues[key],
        };
        selectedObj[key] = selectedItem;
      });
      setSelected(selectedObj);
    }
  }, [addressValues]);

  useEffect(() => {
    if (values.streetName) {
      getStreetNames({
        searchQuery: values["streetName"].substring(0, 6),
        municipalityName: values["municipalityName"],
      });
    }
  }, [values.streetName]);

  const getStreetNames = async (val) => {
    const resp = await fetchStreets(val, values);
    setStreetNames(resp);
    return resp;
  };

  const getCityNames = async (val) => {
    const resp = await fetchCities(val);
    setStreetNames({});
    setMunicipalityName(resp.citiesOptionList);
    // delete streetname
    const tmpSelected = { ...selected };
    delete tmpSelected["streetName"];
    setSelected(tmpSelected);
    handleChange({ name: "streetName", value: undefined });
    setPostals(resp.cityPostals);
    return resp.citiesOptionList;
  };

  const handleSelected = (value) => {
    const selectedVal = { label: value.value, value: value.value };
    const tmpSelected = { ...selected };
    if (value.name === "municipalityName") {
      //delete streetname
      delete tmpSelected["streetName"];
      if (postals[selectedVal.value]) {
        tmpSelected["postalCode"] = postals[selectedVal.value];
        handleChange({ name: "postalCode", value: postals[selectedVal.value] });
      }
    }
    tmpSelected[value.name] = selectedVal;
    setSelected(tmpSelected);
    handleChange(value);
  };

  const handlZipcodeValidation = async () => {
    if (values["postalCode"] && values["postalCode"].length === 4) {
      const [resp] = await getCityNames({
        zipcode: parseInt(values["postalCode"]),
      });

      handleChange({ name: "streetName", value: undefined });
      handleChange({ name: "streetNumber", value: undefined });
      handleSelected({ name: "municipalityName", value: resp.value });
    }
  };

  return (
    <div>
      {addressValues.streetName ? (
        <>
          <FlexWrapper>
            <SearchSelectInput
              async
              cacheOptions={false}
              defaultOptions={municipalityName}
              error={errors.municipalityName}
              id="municipalityName"
              name="municipalityName"
              loadOptions={(e) => getCityNames({ searchQuery: e })}
              onSelected={(value) => handleSelected(value)}
              value={selected.municipalityName || ""}
              noOptionsMessage={() => "no city found"}
            >
              {i18n.t("Municipality")} *
            </SearchSelectInput>
            <TextInput
              error={errors.postalCode}
              onChange={handleChange}
              type="number"
              name="postalCode"
              dataTestId="underwriting_address_postalCode"
              value={values["postalCode"]}
              onBlur={() => handlZipcodeValidation()}
              placeholder="zipcode"
            >
              {i18n.t("Postal code")} *
            </TextInput>
          </FlexWrapper>
          <FlexWrapper>
            <SearchSelectInput
              async
              cacheOptions={false}
              defaultOptions={streetNames}
              error={errors.streetNames}
              id="streetName"
              name="streetName"
              loadOptions={(e) => getStreetNames({ searchQuery: e })}
              onSelected={(value) => handleSelected(value)}
              value={selected.streetName || ""}
              noOptionsMessage={() => "no streets found"}
            >
              {i18n.t("Street")} *
            </SearchSelectInput>
            <FlexWrapper>
              <TextInput
                error={errors.streetNumber}
                onChange={(e) => {
                  e.value = e.value.toLowerCase();
                  handleChange(e);
                }}
                type="text"
                name="streetNumber"
                disabled={!values["streetName"]}
                value={
                  values["streetNumber"] && values["streetName"]
                    ? values["streetNumber"]
                    : ""
                }
              >
                <FlowAdressInputLabel>
                  {i18n.t("Housenr")} *
                </FlowAdressInputLabel>
              </TextInput>
              <TextInput
                error={errors.boxNumber}
                onChange={handleChange}
                type="string"
                name="boxNumber"
                disabled={!values["streetName"]}
                value={values["boxNumber"] ? values["boxNumber"] : ""}
              >
                {i18n.t("Boxnr")}
              </TextInput>
            </FlexWrapper>
          </FlexWrapper>
        </>
      ) : (
        <FlowAddressInputWrapper>
          <FlowAddressInput addressValues={(val) => setAddressValues(val)} />
        </FlowAddressInputWrapper>
      )}
    </div>
  );
};

const FlowAdressInputLabel = styled.p`
  height: 1rem;
`;

const FlowAddressInputWrapper = styled.div`
  height: 9rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 1rem;
  }
`;

FlowAddressContainer.propTypes = {
  errors: object.isRequired,
  values: object.isRequired,
  handleChange: func.isRequired,
};

export default FlowAddressContainer;
