import routingData from "../constants/routingData";
import { useLocation, useHistory, useParams } from "react-router-dom";

const useRouting = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams();

  function currentRouteKey() {
    let foundCurrentKey;
    routingData.map(({ title }) => {
      if (pathname.includes(title)) foundCurrentKey = title;
    });
    return foundCurrentKey;
  }

  function currentRouteObject() {
    const [currentRoute] = routingData.filter(
      ({ title }) => title === currentRouteKey()
    );
    return currentRoute;
  }

  function renderNextRoute(routeIndex = 1) {
    const { nextRoutes } = currentRouteObject();
    const { id } = params;

    history.push(`/session/${nextRoutes[routeIndex]}/${id}`);
  }

  return { renderNextRoute, currentRouteObject };
};

export default useRouting;
