/**
 * Return the sting length
 * @param {string} number
 */

export const stringLength = string => {
  if (typeof string === "string") {
    return string.length;
  }

  return 0;
};
