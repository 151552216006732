import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import { TextInput, MaskedInput } from "wg-fe-ui";
import debounce from "debounce-promise";
import { object, func } from "prop-types";
import {
  companyName,
  cbe,
  email,
  licensePlate,
  vinNumber,
  street,
  city,
  zipcode,
  housenr,
  boxNr,
  telephonenr,
} from "../constants/validationSchemas";
import { checkIfCBEIsValid } from "../helpers/apiRouterService";
import useForm from "../hooks/useForm";
import * as Yup from "yup";
import FlowPolicyHolderInfoFormDefaultInputs from "./FlowPolicyHolderInfoFormDefaultInputs";

const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

const FlowPolicyHolderInfoFormLegal = ({ sendSubmit, defaultValues }) => {
  const [cbeBeingFormatted, setCbeBeingFormatted] = useState(false);
  const validationSchema = Yup.object().shape({
    company_name: companyName,
    cbe: cbe.required.test(
      "is-cbe-valid",
      i18n.t(`The entered CBE no is invalid`),
      async (cbe) => {
        return await debounceCBE(cbe);
      }
    ),
    telephonenr: telephonenr.required.test(
      "Phone number",
      i18n.t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, "");
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),
    email: email,
    streetName: street,
    municipalityName: city,
    postalCode: zipcode,
    streetNumber: housenr,
    boxNumber: boxNr,
    licensePlate: licensePlate,
    vinNumber: vinNumber,
  });

  const debounceCBE = debounce(handleCBEValidation, 1000);

  async function handleCBEValidation(cbe = "") {
    if (cbe === null || cbe === undefined || cbe.length === 0) return 200;
    const [, status] = await checkIfCBEIsValid(cbe.replace(/\./g, ""));
    return status === 200;
  }

  useEffect(() => {
    if (cbeBeingFormatted) {
      values.cbe = undefined;
    }
  }, [cbeBeingFormatted]);

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).map((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
  }, [defaultValues]);

  const handleForm = (e) => {
    e.preventDefault();
    if (cbeBeingFormatted) {
      values.cbeBeingFormatted = true;
      values.cbe = undefined;
    } else {
      values.cbeBeingFormatted = false;
    }
    handleSubmit(sendSubmit);
  };

  return (
    <form onSubmit={handleForm}>
      <FlexWrapper>
        <StyledTextInput
          name="company_name"
          error={errors.company_name}
          onChange={(val) => handleChange(val)}
          value={values.company_name}
        >
          <InputWrapper>{i18n.t("Company name")} *</InputWrapper>
        </StyledTextInput>
        <MaskedInput
          data-test-id="office_info_cbe"
          error={errors.cbe}
          mask="9999.999.999"
          name="cbe"
          onChange={(val) => handleChange(val)}
          value={values?.cbe}
          disabled={cbeBeingFormatted}
        >
          <InputWrapper>{i18n.t("Company registration number")}</InputWrapper>
        </MaskedInput>
      </FlexWrapper>
      <FlowPolicyHolderInfoFormDefaultInputs
        errors={errors}
        values={values}
        handleChange={handleChange}
      />
    </form>
  );
};

const InputWrapper = styled.p`
  height: 1rem;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }
`;

FlowPolicyHolderInfoFormLegal.propTypes = {
  errors: object.isRequired,
  values: object.isRequired,
  handleChange: func.isRequired,
};

export default FlowPolicyHolderInfoFormLegal;
