import React from "react";
import styled from "styled-components";

const GoBack = ({ className, ...otherProps }) => {
  return (
    <GoBackIconContainer className={className} {...otherProps}>
      <GoBackIcon
        viewBox="0 0 15 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9961 3.22314L3.30664 11.9127L11.6054 20.2115"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </GoBackIcon>
    </GoBackIconContainer>
  );
};

const GoBackIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.brand.secondary};
  border: none;
  border-radius: 50%;
  margin-right: 2rem;
  width: 5.6rem;
  height: 5.6rem;
  color: white;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 1.6rem;

  @media screen and (max-width: 800px) {
    margin-right: 3.5rem;
  }
`;

const GoBackIcon = styled.svg`
  width: 100%;
  height: 100%;
  margin-left: -0.2rem;
`;

export default GoBack;
