/* stylelint-disable value-keyword-case */
import React, { useState, useEffect, Suspense } from "react";
import styled from "styled-components";
import Drawer from "react-drag-drawer";
import * as Yup from "yup";
import i18n from "../i18n";
import { ActionButton, SearchSelectInput, TextInput } from "wg-fe-ui";
import DateInput from "../components/DateInput";
import { useParams } from "react-router";
import { string, dateWithOutYear } from "../constants/validationSchemas.js";
import useForm from "../hooks/useForm";
import { retrieveInsurers } from "../helpers/apiRouterService";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const TerminationPopUp = ({ toggleDrawer, toggle, setTerminationData }) => {
  const { id } = useParams();
  const { data } = retrieveStorageById(id) || {};
  const [insurers, setInsurers] = useState([]);

  const SignupSchema = Yup.object().shape({
    insuranceCompany: string.required,
    policyNumber: string.required,
    terminationDate: dateWithOutYear
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {}
  });

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    patchStorage({ insuranceCompanyName: val.label }, id);
    handleChange({ name, value: val ? val.value : "" });
  }

  useEffect(() => {
    getInsurers();
    setDefaultValues();
  }, []);

  const getInsurers = async () => {
    const [response] = await retrieveInsurers();
    const tempInsurers = [];
    for (let key in response) {
      tempInsurers.push({
        value: key,
        label: response[key]
      });
    }
    setInsurers(tempInsurers);
  };

  const closePopUp = () => {
    toggleDrawer(false);
  };

  function handleFormValues(info) {
    setTerminationData(info);
    patchStorage(info, id);
    toggleDrawer(!toggle);
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(handleFormValues);
  }

  function setDefaultValues() {
    const defaultValues = {
      insuranceCompany: data.insuranceCompany,
      policyNumber: data.policyNumber,
      terminationDate: data.terminationDate
    };
    Object.keys(defaultValues).map(name => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  return (
    <Drawer open={true}>
      <InfoContainer>
        <InfoHeader>
          <h2>
            <span>{i18n.t(`Cancel current insurance`)}</span>
            <br />
            {i18n.t(
              `If you skip this step, the cancellation letter will not be generated automatically`
            )}
          </h2>
          <CloseButton data-test-id="close_popup" onClick={() => closePopUp()}>
            X
          </CloseButton>
        </InfoHeader>
        <Form onSubmit={formSubmitHandler}>
          <Suspense fallback={<div>Loading...</div>}>
            <div>
              <SearchSelectInput
                cacheOptions={false}
                error={errors.insuranceCompany}
                options={insurers}
                id="insuranceCompany"
                name="insuranceCompany"
                onChange={val => {
                  handleSelectChange(val, "insuranceCompany");
                }}
                value={handleSelectValue(insurers, "insuranceCompany")}
                noOptionsMessage={() => "no insurers found"}
              >
                {i18n.t("What is your current insurance provider?")}
              </SearchSelectInput>
              <TextInput
                error={errors.policyNumber}
                type="text"
                name="policyNumber"
                onChange={handleChange}
                value={values.policyNumber}
                dataTestId="underwriting_termination_policy_number"
              >
                {i18n.t(`Policy number`)}
              </TextInput>
              <DateInput
                error={errors.terminationDate}
                name="terminationDate"
                dataTestId="underwriting_termination_policy_number"
                onChange={handleChange}
                includeYear={false}
                value={values.terminationDate}
              >
                {i18n.t("Expiration date")}
              </DateInput>
              <ButtonContainer>
                <ActionButton
                  type="submit"
                  value="Submit"
                  data-test-id="drivers_information_submit"
                >
                  {i18n.t("Next")}
                </ActionButton>
              </ButtonContainer>
            </div>
          </Suspense>
        </Form>
      </InfoContainer>
    </Drawer>
  );
};

const InfoContainer = styled.div`
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  width: 45rem;
  overflow: auto;
  z-index: 9999;
  font-family: ${({ theme }) => theme.font};
`;

const InfoHeader = styled.div`
  color: white;
  background-color: ${({ theme }) => theme.brand.secondary};
  padding: 2.2rem 2.2rem 2.2rem 5.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    line-height: 1.3;
    width: 90%;

    & span {
      font-weight: bold;
    }
  }
`;

const CloseButton = styled.p`
  cursor: pointer;
  color: white;
  font-weight: 900;

  &:hover {
    color: ${({ theme }) => theme.brand.lighter};
  }
`;

const Form = styled.form`
  width: 100%;
  position: relative;
  padding: 2.5rem 5.2rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;

  & button {
    margin: 0;
    margin-top: 3rem;
  }
`;

export default TerminationPopUp;
