import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";
import { LoadingSpinner } from "wg-fe-ui";

const SearchVersionList = ({ filterdversionList, disabled, onSelected }) => {
  const [selectedVersion, setSelectedVersion] = useState();
  const [displayedList, setDisplayedList] = useState([]);
  const [sorted, setSorted] = useState();
  const [areVersionsLoading, setAreVersionsLoading] = useState(true);

  useEffect(() => {
    onSelected(selectedVersion);
  }, [selectedVersion]);

  useEffect(() => {
    setDisplayedList(filterdversionList);
  }, [filterdversionList]);

  useEffect(() => {
    setAreVersionsLoading(!displayedList.length);
  }, [displayedList]);

  const handleSort = type => {
    if (sorted === type) {
      setDisplayedList(filterdversionList);
      setSorted(null);
    } else {
      const sortedList = [...filterdversionList];
      sortedList.sort((a, b) => {
        if (a.version[type] > b.version[type]) return 1;
        if (a.version[type] < b.version[type]) return -1;
        return 0;
      });
      setDisplayedList(sortedList);
      setSorted(type);
    }
  };
  return (
    <div>
      <VersionLabel>
        <VersionSpan
          className="version"
          sorted={sorted === "model_year"}
          onClick={() => handleSort("model_year")}
        >
          {i18n.t("Version")}
        </VersionSpan>
        <VersionSpan sorted={sorted === "kw"} onClick={() => handleSort("kw")}>
          {i18n.t("Kw")}
        </VersionSpan>
        <VersionSpan
          sorted={sorted === "model_year"}
          onClick={() => handleSort("model_year")}
        >
          {i18n.t("Model year")}
        </VersionSpan>
      </VersionLabel>
      <VersionList disabled={disabled}>
        {areVersionsLoading && !disabled ? (
          <LoadingBox>
            <LoadingSpinner className="spinner" />
          </LoadingBox>
        ) : null}
        {displayedList.map((versionObj, i) => {
          const { kw, model_year, version, id } = versionObj.version;
          const selectReference = { kw, model_year, id };
          return (
            <Version
              key={`${version}-${i}`}
              onClick={() => setSelectedVersion({ kw, model_year, id })}
              isSelected={
                JSON.stringify(selectReference) ===
                JSON.stringify(selectedVersion)
              }
            >
              <span className="version">{version}</span>
              <span>{kw}</span>
              <span>{model_year}</span>
            </Version>
          );
        })}
      </VersionList>
    </div>
  );
};

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const VersionLabel = styled.li`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.typo.interactive};

  & .version {
    width: 58%;
  }

  & span {
    cursor: pointer;
  }
`;

const VersionSpan = styled.span`
  ${({ sorted }) => (sorted ? `font-weight: 900; color: #6d6d6d;` : null)}
  position: relative;
  display: inline-block;

  &::after {
    position: absolute;
    right: 0;
    content: "v";
    display: flex;
    align-items: center;
    top: 0;
    height: 100%;
    transition: all 0.3s ease;
    opacity: ${props => (props.ordered ? 1 : 0)};
    transform: rotate(
        ${props =>
          props.orderDirection && props.orderDirection !== null ? 0 : "-180deg"}
      )
      translateY(
        ${props =>
          props.orderDirection && props.orderDirection !== null
            ? "-1px"
            : "-3px"}
      );
  }
`;

const Version = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: left;
  color: ${({ theme, isSelected }) => (isSelected ? "white" : theme.typo.text)};

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.brand.primary : "none"};
  cursor: pointer;
  padding: 1rem 2rem;

  & > span:last-child {
    text-align: right;
  }

  & .version {
    width: 50%;
  }

  & .power {
    width: 22%;
  }

  & .year {
    width: 14%;
  }

  :hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
  }
`;

const VersionList = styled.ul`
  background-color: ${({ disabled }) => (disabled ? "hsl(0,0%,95%)" : "white")};
  border: 1px solid ${({ theme }) => theme.ui.outline};
  border-radius: 3px;
  min-height: 16rem;
  overflow-y: scroll;
`;

export default SearchVersionList;
