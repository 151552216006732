import React from "react";
import styled from "styled-components";

const FlowProgressBarStep = ({ icon, percent, active }) => {
  return (
    <>
      <ProgressStep>
        <Circle active={active}>
          <StepIcon alt={icon.key}>{icon}</StepIcon>
        </Circle>

        <MiniProgressBar>
          <Progress percent={percent} />
        </MiniProgressBar>
      </ProgressStep>
    </>
  );
};

const StepIcon = styled.div`
  > svg {
    width: 100%;
    height: 100%;
  }

  width: 100%;
  height: 100%;
`;

const Circle = styled.div`
  padding: 0.7rem;
  width: 6rem;
  height: 6rem;
  background-color: ${({ theme }) => theme.brand.dark};
  border: solid 4px
    ${({ theme, active }) => (active ? theme.brand.secondary : "white")};
  border-radius: 4rem;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 5rem;
    height: 5rem;
  }
`;

const ProgressStep = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:last-of-type {
    width: 6rem;

    & > div:last-child {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    transform: scale(0.95);
  }
`;

const Progress = styled.div`
  background-color: ${({ theme }) => theme.brand.secondary};
  border-radius: 1rem;
  transition: width 0.6s ease;
  will-change: width;
  width: ${props => props.percent}%;
  height: 7px;
`;

const MiniProgressBar = styled.div`
  background-color: white;
  border-radius: 1rem;
  width: 70%;
  height: 0.7rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    width: 45%;
  }
`;

export default FlowProgressBarStep;
