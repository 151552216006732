import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import i18n from "../i18n";
import Div100vh from "react-div-100vh";
import { renderToStaticMarkup } from "react-dom/server";
import BrokerBackground from "../assets/images/bg-broker";
import { getThemeObject } from "../helpers/themingService";
import elvasLogo from "../assets/images/elvas_logo.svg";

const ThankyouPage = () => {
  const location = useLocation();
  let Status = "";
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.has("Status")) Status = urlParams.get("Status");

  const dataUri = `url("data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<BrokerBackground colors={getThemeObject()} />)
  )}")`;

  return (
    <Div100vh>
      <Background img={dataUri}>
        <Center>
          <Box>
            <VStack>
              <ElvasLogo src={elvasLogo} alt="" />
              <RoundImage
                src="https://files.wegroup.be/avatars/avatar-louise.svg"
                alt="louise"
              />
            </VStack>
            {Status === "SIGNED" ? (
              <>
                <H3>{i18n.t("Thank you for choosing Elvas")}</H3>
                <LargeBody>
                  {i18n.t(
                    "We have received your information and will contact you as soon as possible"
                  )}
                </LargeBody>
              </>
            ) : (
              <>
                <H3>
                  {i18n.t(
                    "Something went wrong with generating your documents please contact elvas"
                  )}
                </H3>
              </>
            )}
          </Box>
        </Center>
      </Background>
    </Div100vh>
  );
};

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.2rem;
`;

const RoundImage = styled.img`
  max-width: 8rem;
  border-radius: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
`;

const ElvasLogo = styled.img`
  width: 14rem;
  margin-bottom: 2.4rem;
`;

const Box = styled.div`
  background-color: white;
  max-width: 560px;
  padding: 5.6rem;
  border-radius: 10px;
  margin: 1.6rem;
`;

const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const H3 = styled.div`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
`;

const LargeBody = styled.div`
  margin-top: 1.6rem;
  font-size: 18px;
  text-align: center;
`;

const Background = styled.main`
  background-image: ${(props) => props.img},
    linear-gradient(
      180deg,
      ${({ theme }) => theme.brand.primary} 45.86%,
      ${({ theme }) => theme.brand.secondary} 100%
    );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.font};
  padding-top: 20px;
`;

export default ThankyouPage;
