import React from "react";
import styled from "styled-components";
import InfoPopup from "./InfoPopup";
import i18n from "../i18n";

const Options = ({ title, helper, totalPremium }) => {
  var myObj = {
    style: "currency",
    currency: "EUR"
  };

  return (
    <OptionsInformation>
      <OptionTitle>{title}</OptionTitle>
      <TotalCost>
        {totalPremium.toLocaleString("nl-BE", myObj)}/{i18n.t("y")}
      </TotalCost>
      <StyledInfoPopup title={title} info={helper} />
    </OptionsInformation>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  height: 2rem;
  justify-self: end;
  line-height: 1.13;
  padding-left: 0.25rem;
  width: 2rem;
`;

const OptionTitle = styled.h2`
  font-weight: 600;
`;
const TotalCost = styled.p`
  text-align: right;
`;

const OptionsInformation = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 12rem 4.1rem;
  margin: 1rem;
`;

export default Options;
