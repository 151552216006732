import { format } from "date-fns";
import { retrieveCarPrice } from "./apiRouterService";
import { parseIncDateToObject } from "./dateService";
import { patchStorage, retrieveStorageById } from "./storeService";

export const getCarPrice = async (id) => {
  const { data } = retrieveStorageById(id) || {};

  const {
    firstName,
    lastName,
    birth,
    driverLicenseType,
    issueLicenseDate,
    numberOfAccidentsLastFiveYears,
    specialEmployment,
    kWh,
    brand,
    model,
    usedFor,
    invoice_value,
    options,
    version,
    registration_first,
    model_year,
    licensePlate,
    vinNumber,
    pricingQuery,
  } = data || {};

  const currentDateObj = parseIncDateToObject(
    format(new Date(registration_first), "dd/MM/yyyy")
  );

  const payload = {
    car: {
      kwh: kWh,
      motor_type: "ELE",
      brand,
      version: version ? version.substring(0, 34) : null,
      model,
      model_year,
      used_for: usedFor,
      value: parseInt(invoice_value),
      options_value: parseInt(options),
      registration_first: currentDateObj,
      number_plate: licensePlate,
      chassis_number: vinNumber,
      drivers: [
        {
          first_name: firstName,
          last_name: lastName,
          birth: parseIncDateToObject(birth),
          issue_license_date: parseIncDateToObject(issueLicenseDate),
          primary: true,
          number_of_accidents_last_five_years: numberOfAccidentsLastFiveYears,
          accident_statement: null,
          special_employment: specialEmployment === "yes" ? true : false,
          driver_license_type: driverLicenseType.toUpperCase(),
        },
      ],
    },
    quote_specifications: {
      currency: "EUR",
      payment_interval: "annual",
      save_quote: true,
    },
  };

  const [resp, status] = await retrieveCarPrice(payload, pricingQuery);
  return [resp, status];
};

export const patchquoteOptionsAndPricing = (id, response) => {
  const { premium, quote, id: quoteId } = response || {};

  const { options, base, details } = quote || {};

  const optionsAndPricing = {
    premium: {
      totalPremium: premium.total_premium,
      netPremium: premium.net_premium,
      taxesInEuro: premium.taxes_in_euro,
      policyCosts: premium.policy_costs,
    },
    quote: {
      id: quoteId,
      base: base,
      franchise: details.franchise,
      options: options,
    },
  };

  patchStorage(optionsAndPricing, id);
};
